import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import {
  AuthzContext,
  AuthzResult,
  ClaimsAuthzService,
  grantedAuthzResult,
  UserPermission
} from '@mri-platform/resource-authz';
import { combineLatest, merge, Observable, ReplaySubject, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ResourceAuthzSessionService } from '../resource-authz-session.service';

@Directive({
  selector: '[mriResourceAuthzCheck]'
})
export class ResourceAuthzCheckDirective implements OnInit, OnDestroy {
  @Input() set mriResourceAuthzCheckOf(value: AuthzContext[] | AuthzContext) {
    if (value == null) return;

    this.authzContext.next(value);
  }

  @Input() set mriResourceAuthzCheckUserPermissions(value: UserPermission[] | undefined) {
    this.permissionsFromTemplate.next(value);
  }

  private authzContext = new ReplaySubject<AuthzContext[] | AuthzContext>(1);
  private permissionsFromTemplate = new ReplaySubject<UserPermission[] | undefined>(1);
  private subscription = new Subscription();

  private context = {
    ['$implicit']: grantedAuthzResult,
    isPlaceholder: true
  };

  constructor(
    private viewContainerRef: ViewContainerRef,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private templateRef: TemplateRef<any>,
    private claimsAuthzService: ClaimsAuthzService,
    private userPermissionsService: ResourceAuthzSessionService,
    private cdr: ChangeDetectorRef
  ) {}

  private checkAccess(authzContext: AuthzContext[] | AuthzContext, permissions: UserPermission[]) {
    const all = this.claimsAuthzService.checkAccessMany(authzContext, permissions);
    return this.claimsAuthzService.mergeAccessCheckResults(all);
  }

  ngOnInit() {
    const injectedPermissions$ = this.userPermissionsService.permissions$.pipe(takeUntil(this.permissionsFromTemplate));
    const userPermissions$ = merge(this.permissionsFromTemplate, injectedPermissions$).pipe(map(x => x ?? []));

    const permissionCheck$: Observable<AuthzResult> = combineLatest([this.authzContext, userPermissions$]).pipe(
      map(([authzContext, permissions]) => this.checkAccess(authzContext, permissions))
    );

    this.subscription = permissionCheck$.subscribe(authzResult => {
      this.context['$implicit'] = authzResult;
      this.context.isPlaceholder = false;
      this.cdr.markForCheck();
    });

    this.viewContainerRef.createEmbeddedView(this.templateRef, this.context);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
