import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DataSource } from '@mri-platform/import-export/common-state';
import {
  FormControls,
  FormModel,
  FormState,
  connectFormObservables,
  createFormObservables
} from '@mri-platform/shared/common-ui';
import { clone, keysOf, taggedNamespace } from '@mri-platform/shared/core';
import { EntityList, emptyEntityList } from '@mri-platform/shared/entity';
import { RxState } from '@rx-angular/state';
import { selectSlice } from '@rx-angular/state/selections';
import { Observable, asapScheduler } from 'rxjs';
import { map, observeOn, withLatestFrom } from 'rxjs/operators';

type DataSourceControl = Pick<DataSource, 'id'>;

const initialFormState: FormModel<DataSourceControl> = {
  id: ['']
};

interface ComponentState extends FormState<DataSource> {
  list: EntityList<DataSource>;
}

type PublicState = Pick<ComponentState, 'list'>;

type ViewModel = PublicState;

const initialPublicState: PublicState = {
  list: emptyEntityList()
};

const initialState: ComponentState = {
  ...initialPublicState,
  isDirty: false,
  isValid: false,
  // OK(ish) as by the time our component is initialized, model will be assigned
  model: undefined as never
};

@Component({
  selector: 'mri-ie-data-source-selection',
  templateUrl: './data-source-selection.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class DataSourceSelectionComponent implements OnInit {
  @HostBinding('attr.data-testid') testId = 'DataSourceSelectionComponent';

  @Input() set model(model: DataSource) {
    if (!model) {
      return;
    }
    const { id } = model;
    this.initalDataSourceId = model;
    this.form.patchValue({ id }, { emitEvent: false });
    if (id) {
      this.form.controls.id.markAsDirty();
    }
  }

  @Input() set list(list: EntityList<DataSource>) {
    this.state.set({ list });

    this.form.reset(undefined, { emitEvent: false });
  }

  // make sure to emit dirtyChange and validChanges on the next turn of the event loop
  // (required when switching between instances to cause change detection to "see" our changes)
  @Output() dirtyChanges = this.state.select('isDirty').pipe(observeOn(asapScheduler));
  @Output() validChanges = this.state.select('isValid').pipe(observeOn(asapScheduler));
  @Output() valueChanges = this.state.select('model');

  initalDataSourceId?: DataSource;

  get controls(): FormControls<DataSourceControl> {
    return this.form.controls as FormControls<DataSourceControl>;
  }
  form = this.createForm();
  vm$: Observable<ViewModel>;

  constructor(
    private fb: FormBuilder,
    private state: RxState<ComponentState>
  ) {
    const tag = taggedNamespace(this.testId);

    // Set initial state in RxState
    this.state.set(initialState);

    // Connect any observable-driven items to state for items in ComponentState...

    const { dirtyChanges$, validChanges$, valueChanges$ } = createFormObservables<DataSource>(this.form, {
      tagPrefix: this.testId
    });

    const selectedDataSource$ = valueChanges$.pipe(
      withLatestFrom(this.state.select('list')),
      map(([formValue, list]) => list.map[formValue.id])
    );
    connectFormObservables(
      {
        dirtyChanges$,
        validChanges$,
        valueChanges$: selectedDataSource$.pipe(
          // same value push it back again
          clone()
        )
      },
      this.state
    );

    // Create ViewModel (Projections + PublicState)...

    this.vm$ = this.state.select(selectSlice(keysOf(initialPublicState))).pipe(tag('vm'));

    // side effects if any...
    // none
  }
  ngOnInit() {
    // first time only need to set and to avoid circular calling
    this.state.set({ model: this.initalDataSourceId });
  }

  private createForm() {
    return this.fb.group(initialFormState);
  }
}
