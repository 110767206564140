import { ChangeDetectionStrategy, Component, HostBinding, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SelectionType, SourceType } from '@mri-platform/import-export/common-state';
import { connectForm, FormModel, FormState } from '@mri-platform/shared/common-ui';
import { RxState } from '@rx-angular/state';
import { asapScheduler } from 'rxjs';
import { observeOn } from 'rxjs/operators';

const defaultSelection: SourceType = SourceType.FILE;

type ComponentState = FormState<SelectionType>;

// type PublicState = Pick<ComponentState, 'xxx' | 'yyy'>;

// interface Projections { }

// type ViewModel = PublicState & Projections;

// const initialPublicState: PublicState = { };

const initialState: ComponentState = {
  isDirty: false,
  isValid: false,
  // OK(ish) as by the time our component is initialized, model will be assigned
  model: undefined as never
};

const initialFormState: FormModel<SelectionType> = {
  selection: [defaultSelection, Validators.required]
};

@Component({
  selector: 'mri-ie-selection',
  templateUrl: './selection.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class SelectionComponent {
  @HostBinding('attr.data-testid') testId = 'SelectionComponent';

  @Input() label = 'Source';

  @Input() set model(model: SelectionType) {
    if (!model) {
      return;
    }
    const { selection } = model;
    this.state.set({ model });
    this.form.reset(undefined, { emitEvent: false });
    this.form.patchValue({ selection: selection ?? defaultSelection });
  }
  form = this.createForm();
  sourceType = SourceType;

  @Output() dirtyChanges = this.state.select('isDirty').pipe(observeOn(asapScheduler));
  @Output() validChanges = this.state.select('isValid').pipe(observeOn(asapScheduler));
  @Output() valueChanges = this.state.select('model');

  constructor(
    private fb: FormBuilder,
    private state: RxState<ComponentState>
  ) {
    // Set initial state in RxState
    this.state.set(initialState);

    // Connect any observable-driven items to state for items in ComponentState...

    connectForm(this.form, this.state, { tagPrefix: this.testId });

    // Create projections (calculations from ComponentState)...
    // none

    // Create ViewModel (Projections + PublicState)...
    // none

    // side effects if any...
    // none
  }

  createForm() {
    return this.fb.group(initialFormState);
  }
}
