import { Component, HostBinding, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'mri-master-detail-header',
  template: `
    <ng-content selector="mri-avatar"></ng-content>
    <h3 class="mri-heading mri-heading--3 mri-master-detail__detail-title">
      <ng-content
        *ngIf="!title; else titleTemplate"
        select="mri-master-detail-header-title, [mriMasterDetailHeaderTitle]"
      ></ng-content>
    </h3>
    <ng-template #titleTemplate>
      <mri-master-detail-header-title>{{ title }}</mri-master-detail-header-title>
    </ng-template>
    <ng-container *ngTemplateOutlet="toolbarTemplate"></ng-container>
  `,
  styleUrl: 'master-detail-header.component.scss'
})
export class MasterDetailHeaderComponent {
  @HostBinding('class.mri-master-detail__detail-header') readonly hostClass = true;
  @HostBinding('class.no-background-color')
  @Input()
  noBackgroundColor = false;
  @Input() title = '';
  toolbarTemplate!: TemplateRef<never>;
}
