import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { InMemoryDbService, InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { environment } from './environments/environment';
import { FeatureDbProvider } from './feature-flags/dev-dataset';
import { allEnabledFeatureFlagsProvider } from './feature-flags/fake-feature-flags.service';
import { FakeBackendConfigProvider } from './testing/fake-backend-config.provider';
import { FakeDbImplService } from './testing/fake-db-impl.service';
import { FakeDbService } from './testing/fake-db.service';

interface CoreModuleOptions {
  /**
   * Configure a real feature flag service or no?
   *
   * Defaults to `true`
   */
  useFeatureFlags?: boolean;
}

@NgModule({
  imports: [CommonModule]
})
export class CoreModule {
  static forRoot({ useFeatureFlags }: CoreModuleOptions = {}): ModuleWithProviders<CoreModule> {
    const inMemoryApiProviders = environment.inMemoryApiEnabled
      ? [
          InMemoryWebApiModule.forRoot(FakeDbImplService).providers,
          FeatureDbProvider,
          FakeBackendConfigProvider,
          { provide: FakeDbService, useExisting: InMemoryDbService }
        ]
      : [];

    return {
      ngModule: CoreModule,
      providers: [inMemoryApiProviders, useFeatureFlags === false ? allEnabledFeatureFlagsProvider : []]
    };
  }
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
