import { ChangeDetectionStrategy, Component, HostBinding, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AccessToken } from '@mri-platform/import-export/common-state';
import {
  FormControls,
  FormModel,
  FormValidators,
  connectFormObservables,
  createFormObservables,
  setSyncValidators
} from '@mri-platform/shared/common-ui';
import { RxState } from '@rx-angular/state';
import { selectSlice } from '@rx-angular/state/selections';
import { Observable, asapScheduler, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, observeOn, shareReplay, startWith, withLatestFrom } from 'rxjs/operators';
import { Client, Database, User } from '../../view-models';

interface AccessTokenField {
  isClientExist: boolean;
  isUserExist: boolean;
  isDatabaseExist: boolean;
}

interface ViewModel {
  accesstokens: AccessToken[];
  clients: Client[];
  users: User[];
  databases: Database[];
}
interface AccessTokenState extends ViewModel {
  model: AccessToken;
  isDirty: boolean;
  isValid: boolean;
  selectedClientId: string;
  selectedUserName: string;
  selectedDatabaseId: string;
}

const initialAccessTokenState: AccessTokenState = {
  model: undefined as never,
  selectedClientId: '',
  selectedUserName: '',
  selectedDatabaseId: '',
  isDirty: false,
  isValid: false,
  accesstokens: [],
  clients: [],
  users: [],
  databases: []
};

const initialState: FormModel<Omit<AccessToken, 'platformId' | 'userId' | 'tokenExpiryTime'>> = {
  id: [''],
  clientId: [''],
  clientName: [''],
  userName: [''],
  databaseId: [''],
  databaseName: ['']
};

const commonValidators: FormValidators<AccessToken> = {
  clientId: [Validators.required],
  userName: [Validators.required],
  databaseId: [Validators.required]
};

const getValidators = (isAccessTokenFieldsExist: AccessTokenField) => {
  const { isClientExist, isUserExist, isDatabaseExist } = isAccessTokenFieldsExist;
  let fieldBaseValidators: FormValidators<AccessToken> = { ...commonValidators };
  fieldBaseValidators = !isClientExist ? { ...fieldBaseValidators, clientId: [] } : fieldBaseValidators;
  fieldBaseValidators = !isUserExist ? { ...fieldBaseValidators, userName: [] } : fieldBaseValidators;
  fieldBaseValidators = !isDatabaseExist ? { ...fieldBaseValidators, databaseId: [] } : fieldBaseValidators;
  return fieldBaseValidators;
};
type AccessTokenKey = keyof Pick<AccessToken, 'clientId' | 'userName'> | '';
type CommonContextKeys<M> = keyof AccessToken & keyof M;
const distinctList = <M, K extends CommonContextKeys<M>>(accesstokens: M[], key: K) => {
  return accesstokens.filter((context, i, arr) => arr.findIndex(c => c[key] === context[key]) === i);
};

const getAccessTokenFieldsExist = (accesstokens: AccessToken[]) => {
  const [context] = (accesstokens.length && accesstokens) || [];
  return {
    isClientExist: !context || Object.prototype.hasOwnProperty.call(context, 'clientId'),
    isUserExist: !context || Object.prototype.hasOwnProperty.call(context, 'userName'),
    isDatabaseExist: !context || Object.prototype.hasOwnProperty.call(context, 'databaseId')
  };
};

@Component({
  selector: 'mri-ie-access-token',
  templateUrl: './access-token.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class AccessTokenComponent {
  @HostBinding('attr.data-testid') testId = 'AccessTokenComponent';

  get accesstokens(): AccessToken[] {
    return this.state.get('accesstokens');
  }

  @Input() set accesstokens(value: AccessToken[]) {
    this.state.set({ accesstokens: value ?? [] });
  }

  @Input() set formState(value: AccessToken) {
    if (!value) {
      this.controls.databaseId.reset(null, { emitEvent: false });
      this.controls.databaseName.reset(null, { emitEvent: false });
      return;
    }

    if (value.clientId) {
      this.state.set({ model: value });
      this.form.patchValue(value);
      if (value.databaseId) {
        this.controls.databaseId.markAsDirty();
      }
    }
  }
  form = this.createForm();

  @Output() dirtyChanges = this.state.select('isDirty').pipe(observeOn(asapScheduler));
  @Output() validChanges = this.state.select('isValid').pipe(observeOn(asapScheduler));
  @Output() valueChanges = this.state.select('model');

  validators: FormValidators<AccessToken> = {};
  get controls(): FormControls<AccessToken> {
    return this.form.controls as unknown as FormControls<AccessToken>;
  }

  accesstokens$ = this.state.select('accesstokens');
  isAccessTokenFieldsExist$ = this.accesstokens$.pipe(
    map(getAccessTokenFieldsExist),
    startWith({
      isClientExist: false,
      isUserExist: false,
      isDatabaseExist: false
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  vm$: Observable<ViewModel> = this.state.select(selectSlice(['clients', 'users', 'databases', 'accesstokens']));

  context!: AccessToken;

  constructor(
    private fb: FormBuilder,
    private state: RxState<AccessTokenState>
  ) {
    this.state.set(initialAccessTokenState);

    const { dirtyChanges$, validChanges$, valueChanges$ } = createFormObservables<AccessToken>(this.form, {
      tagPrefix: this.testId
    });

    connectFormObservables(
      {
        dirtyChanges$,
        validChanges$,
        valueChanges$: this.createModel$(valueChanges$).pipe(filter(c => !!c.clientId))
      },
      this.state
    );

    const validators$ = this.isAccessTokenFieldsExist$.pipe(map(getValidators));
    this.state.hold(validators$, validators => setSyncValidators(this.form, validators));

    const clientChanges$ = this.state.select('selectedClientId');
    this.state.hold(clientChanges$, _ => this.setValuesOnClientChanges());
    const clientUserChanges$ = this.state.select(selectSlice(['selectedClientId', 'selectedUserName']));
    this.state.hold(clientUserChanges$, _ => this.controls.databaseId.reset(null, { emitEvent: false }));
    //Client Handling
    this.initializeClients();
    //User Handling
    this.initializeUsers();
    //Database Handling
    this.initializeDatabases();
  }

  private setValuesOnClientChanges() {
    this.state.set({ selectedUserName: '' });
    this.controls.userName.reset(null, { emitEvent: false });
    this.state.set({ databases: [] });
  }

  public createModel$(valueChanges$: Observable<AccessToken>) {
    return valueChanges$.pipe(
      distinctUntilChanged(),
      withLatestFrom(
        this.accesstokens$,
        this.isAccessTokenFieldsExist$,
        (valueChanges, accesstokens, isAccessTokenFieldsExist) => {
          const { isClientExist, isUserExist, isDatabaseExist } = isAccessTokenFieldsExist;
          const context = accesstokens.find(contextData => {
            const clientExist = (isClientExist && contextData.clientId === valueChanges.clientId) || !isClientExist;
            const userNameExist = (isUserExist && contextData.userName === valueChanges.userName) || !isUserExist;
            const databaseExist =
              (isDatabaseExist && contextData.databaseId === valueChanges.databaseId) || !isDatabaseExist;
            return clientExist && userNameExist && databaseExist;
          });
          let model = valueChanges;
          model = { ...model, id: context?.id ? context?.id : 0 };

          if (context) {
            model = isClientExist ? { ...model, clientName: context.clientName } : model;
            model = isDatabaseExist ? { ...model, databaseName: context.databaseName } : model;
          }
          return model;
        }
      )
    );
  }

  public initializeClients() {
    const client$ = this.controls.clientId.valueChanges;
    this.state.connect('selectedClientId', client$);
    const clients$ = combineLatest([this.state.select('accesstokens'), this.isAccessTokenFieldsExist$]).pipe(
      map(([accesstokens, isAccessTokenFieldsExist]) => {
        const { isClientExist } = isAccessTokenFieldsExist;
        if (!isClientExist) {
          return [];
        }
        return distinctList(
          accesstokens.map(context => ({
            clientId: context.clientId,
            clientName: context.clientName
          })),
          'clientId'
        );
      })
    );
    this.state.connect('clients', clients$);
    this.state.hold(clients$, clients => {
      if (clients?.length === 1) {
        this.controls.clientId.patchValue(clients[0].clientId);
        this.controls.clientName.patchValue(clients[0].clientName);
      }
    });
  }

  public initializeUsers() {
    const user$ = this.controls.userName.valueChanges;
    this.state.connect('selectedUserName', user$);
    const users$ = combineLatest([
      this.state.select('accesstokens'),
      this.isAccessTokenFieldsExist$,
      this.state.select('selectedClientId')
    ]).pipe(
      map(([accesstokens, isAccessTokenFieldsExist, selectedClientId]) => {
        const { isClientExist, isUserExist } = isAccessTokenFieldsExist;
        if (!isUserExist) {
          return [];
        }
        return distinctList(
          accesstokens
            .filter(context => (!!selectedClientId && context.clientId === +selectedClientId) || !isClientExist)
            .map(context => ({ userName: context.userName })),
          'userName'
        );
      })
    );
    this.state.connect('users', users$);
    this.state.hold(users$, users => {
      if (users?.length === 1) {
        this.controls.userName.patchValue(users[0].userName);
      }
    });
  }

  public initializeDatabases() {
    const databases$ = combineLatest([
      this.state.select('accesstokens'),
      this.isAccessTokenFieldsExist$,
      this.state.select('selectedClientId'),
      this.state.select('selectedUserName')
    ]).pipe(
      map(([accesstokens, isAccessTokenFieldsExist, selectedClientId, selectedUserName]) => {
        const { isClientExist, isDatabaseExist, isUserExist } = isAccessTokenFieldsExist;
        if (!isDatabaseExist) {
          return [];
        }
        let filterData = '';
        let key: AccessTokenKey = '';
        if (!!selectedClientId && !isUserExist) {
          filterData = selectedClientId;
          key = 'clientId';
        } else if (!!selectedUserName && isUserExist) {
          filterData = selectedUserName;
          key = 'userName';
        }
        return distinctList(
          accesstokens
            .filter(
              context =>
                (!!filterData &&
                  !!key &&
                  (selectedClientId ? context.clientId === +selectedClientId : '') &&
                  context[key] === filterData) ||
                (!isClientExist && !isUserExist)
            )
            .map(context => ({
              databaseName: context.databaseName,
              databaseId: context.databaseId
            })),
          'databaseId'
        );
      }),
      map(databases => databases.sort((a, b) => (a.databaseName as string).localeCompare(b.databaseName as string)))
    );
    this.state.connect('databases', databases$);
    this.state.hold(databases$, databases => {
      if (databases?.length === 1) {
        this.controls.databaseId.patchValue(databases[0].databaseId);
        this.controls.databaseName.patchValue(databases[0].databaseName);
      }
    });
  }

  private createForm() {
    return this.fb.group(initialState);
  }
}
