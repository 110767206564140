<p class="mri-mb-medium mri-mt-auto">
  For best results when using an image, choose a square image at least 64 pixels wide and tall. When using text, enter
  1-3 characters for the caption.
</p>
<form [formGroup]="form" class="k-form k-form-horizontal">
  <kendo-formfield class="mri-ml-medium">
    <div class="k-input-container">
      <input
        type="radio"
        id="useImage"
        #useImage
        name="selection"
        value="image"
        formControlName="selection"
        kendoRadioButton
      />
      <kendo-label class="k-radio-label" [for]="useImage" text="Use image"></kendo-label>
    </div>
  </kendo-formfield>
  <kendo-formfield>
    <kendo-label [for]="iconImage" text="Image file"></kendo-label>
    <kendo-upload
      #iconImage
      formControlName="fileUploaded"
      [saveUrl]="imageUploadUrl"
      [restrictions]="uploadFileRestrictions"
      [autoUpload]="false"
      [multiple]="false"
      [saveHeaders]="uploadHeaders"
      (success)="uploadSuccess($event)"
      (error)="uploadFailure($event)"
    >
      <kendo-upload-messages select="Select file..."> </kendo-upload-messages>
    </kendo-upload>
    <kendo-formhint>{{ uploadFileRestrictions | fileRestrictionsMessage }}</kendo-formhint>
  </kendo-formfield>
  <kendo-formfield class="mri-ml-medium">
    <div class="k-input-container">
      <input
        type="radio"
        id="useText"
        #useText
        name="selection"
        value="icon"
        formControlName="selection"
        kendoRadioButton
      />
      <kendo-label class="k-radio-label" [for]="useText" text="Use text"></kendo-label>
    </div>
  </kendo-formfield>
  <div class="row">
    <kendo-formfield class="col-3 col-sm-12">
      <kendo-label [for]="iconInitials" class="required" text="Caption"></kendo-label>
      <input
        #iconInitials
        id="iconInitials"
        formControlName="iconInitials"
        [readOnly]="!editableControls.iconInitials"
        kendoTextBox
        class="avatar-initials"
      />
      <kendo-formerror *ngIf="formControls.iconInitials.errors?.required"> Caption is required. </kendo-formerror>
      <kendo-formerror *ngIf="formControls.iconInitials.errors?.maxlength"
        >Caption should be 1-3 characters.</kendo-formerror
      >
      <kendo-formerror *ngIf="formControls.iconInitials.errors?.alphanumeric">
        Caption should be alphanumeric.
      </kendo-formerror>
    </kendo-formfield>
    <kendo-formfield class="col-3 col-sm-12">
      <kendo-label [for]="iconBackgroundColor" text="Color"></kendo-label>
      <kendo-colorpicker
        #iconBackgroundColor
        formControlName="iconBackgroundColor"
        [format]="'hex'"
        [gradientSettings]="{ opacity: false }"
      ></kendo-colorpicker>
    </kendo-formfield>
  </div>
</form>
