import { Size, Rect as geomRect, Circle as geomCircle } from '../geometry';
import { Rect as drawRect, Circle as drawCircle, Path } from '../drawing';
import Group from '../shapes/group';
import { definitionId } from '../util';
import { PATTERN } from '../core/constants';
const defaultColor = "#aba4a6";
const defaultLine = {
  width: 2,
  gap: 18
};
const defaultDot = {
  radius: 10,
  gap: 10
};
const defaultGrid = {
  size: 18,
  gap: 2
};
export class Pattern extends Group {
  get nodeType() {
    return PATTERN;
  }
  constructor(options) {
    super();
    const {
      width,
      height
    } = options;
    this._size = Size.create([width, height]);
    this.id = definitionId();
  }
  size(value) {
    if (value) {
      this._size = Size.create(value);
      return this;
    }
    return this._size;
  }
}
const drawBackground = (pattern, color, size) => {
  if (color) {
    pattern.append(new drawRect(new geomRect([0, 0], size), {
      fill: {
        color
      },
      stroke: null
    }));
  }
};
export function dotsPattern(options = {}) {
  const {
    gap = defaultDot.gap,
    radius = defaultDot.radius,
    color = defaultColor,
    background
  } = options;
  const shapeOptions = {
    fill: {
      color
    },
    stroke: null
  };
  const size = 4 * radius + 2 * gap;
  const yC2 = 3 * radius + 1.5 * gap;
  const center1 = [size / 2, radius + 1 / 2 * gap];
  const center2 = [0, yC2];
  const center3 = [size, yC2];
  const pattern = new Pattern({
    width: size,
    height: size
  });
  drawBackground(pattern, background, [size, size]);
  pattern.append(new drawCircle(new geomCircle(center1, radius), shapeOptions), new drawCircle(new geomCircle(center2, radius), shapeOptions), new drawCircle(new geomCircle(center3, radius), shapeOptions));
  return pattern;
}
;
export function verticalStripesPattern(options = {}) {
  const {
    gap = defaultLine.gap,
    width = defaultLine.width,
    color = defaultColor,
    background
  } = options;
  const size = width + gap;
  const shapeOptions = {
    fill: null,
    stroke: {
      color,
      width: width / 2
    }
  };
  const pattern = new Pattern({
    width: size,
    height: size
  });
  drawBackground(pattern, background, [size, size]);
  const xStart = width / 4;
  const xEnd = size - width / 4;
  const startLine = new Path(shapeOptions);
  startLine.moveTo(xStart, 0).lineTo(xStart, size);
  const endLine = new Path(shapeOptions);
  endLine.moveTo(xEnd, 0).lineTo(xEnd, size);
  pattern.append(startLine, endLine);
  return pattern;
}
;
export function crosshatchPattern(options = {}) {
  const {
    gap = defaultLine.gap,
    width = defaultLine.width,
    color = defaultColor,
    background
  } = options;
  const size = Math.sqrt(2) * (width + gap);
  const shapeOptions = {
    fill: null,
    stroke: {
      color,
      width
    }
  };
  const pattern = new Pattern({
    width: size,
    height: size
  });
  drawBackground(pattern, background, [size, size]);
  const line1 = new Path(shapeOptions);
  line1.moveTo(0, 0).lineTo(size, size);
  const line2 = new Path(shapeOptions);
  line2.moveTo(size, 0).lineTo(0, size);
  pattern.append(line1, line2);
  return pattern;
}
;
export function diagonalStripesPattern(options = {}) {
  const {
    gap = defaultLine.gap,
    width = defaultLine.width,
    color = defaultColor,
    background
  } = options;
  const size = Math.sqrt(2) * (width + gap);
  const shapeOptions = {
    fill: null,
    stroke: {
      color,
      width,
      lineCap: 'square'
    }
  };
  const pattern = new Pattern({
    width: size,
    height: size
  });
  drawBackground(pattern, background, [size, size]);
  const line1 = new Path(shapeOptions);
  line1.moveTo(0, size / 2).lineTo(size / 2, 0);
  const line2 = new Path(shapeOptions);
  line2.moveTo(size / 2, size).lineTo(size, size / 2);
  pattern.append(line1, line2);
  return pattern;
}
;
export function gridPattern(options = {}) {
  const {
    gap = defaultGrid.gap,
    size: squareSize = defaultGrid.size,
    color = defaultColor,
    background
  } = options;
  const size = squareSize + gap;
  const halfGap = gap / 2;
  const shapeOptions = {
    fill: {
      color
    },
    stroke: null
  };
  const pattern = new Pattern({
    width: size,
    height: size
  });
  drawBackground(pattern, background, [size, size]);
  const rect = new drawRect(new geomRect([halfGap, halfGap], [squareSize, squareSize]), shapeOptions);
  pattern.append(rect);
  return pattern;
}
;