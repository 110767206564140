import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Data, Router, RouterStateSnapshot } from '@angular/router';
import { AuthzContext, ClaimsAuthzService } from '@mri-platform/resource-authz';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ResourceAuthzSessionService } from './resource-authz-session.service';

interface ResourceAuthzData extends Data {
  authzContext: AuthzContext;
}
interface ExtendedActivatedRouteSnapshot extends ActivatedRouteSnapshot {
  data: ResourceAuthzData;
}
@Injectable({
  providedIn: 'root'
})
export class ResourceAuthzGuard {
  constructor(
    private claimsAuthService: ClaimsAuthzService,
    private session: ResourceAuthzSessionService,
    private router: Router
  ) {}

  canActivate(next: ExtendedActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    return this.session.permissions$.pipe(
      map(permissions => {
        const {
          data: { authzContext }
        } = next;
        if (authzContext == null || permissions == null) {
          return true;
        }
        return this.claimsAuthService.checkAccess(authzContext, permissions).isGranted;
      }),
      tap(isGranted => {
        if (!isGranted) {
          this.router.navigateByUrl('unauthorized');
        }
      })
    );
  }
}
