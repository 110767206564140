<ng-container *ngIf="vm$ | async as vm">
  <mri-shared-drawer headerTitle="Report" [isOpen]="true" (closeDrawer)="closeDrawer.next()">
    <div *mriSharedDrawerBody class="container mri-ph-medium">
      <form
        [formGroup]="form"
        class="k-form k-form-horizontal"
        *mriResourceAuthzCheck="let canSelectClients of vm.DownloadAcrossClientsPermission"
      >
        <div *ngIf="canSelectClients.isGranted">
          <mri-master-detail-header [noBackgroundColor]="true" title="Select client(s)"></mri-master-detail-header>
          <div class="row">
            <kendo-formfield class="col-lg-12">
              <kendo-label for="Select" text="Select" class="text-right"></kendo-label>
              <ul class="k-input-list k-input-list--horizontal">
                <li>
                  <input #all kendoRadioButton type="radio" [value]="true" formControlName="allClients" />
                  <kendo-label [for]="all" class="k-radio-label" text="All"></kendo-label>
                </li>
                <li>
                  <input #custom kendoRadioButton type="radio" [value]="false" formControlName="allClients" />
                  <kendo-label [for]="custom" class="k-radio-label" text="Custom"></kendo-label>
                </li>
              </ul>
            </kendo-formfield>
          </div>
          <div class="row">
            <kendo-formfield class="col-lg-12">
              <kendo-label class="text-right" [for]="clients" text="Client(s)"></kendo-label>
              <kendo-multiselect
                #clients
                formControlName="clientIds"
                mriSharedKendoRequired
                [data]="vm.clients"
                textField="licenseId"
                valueField="id"
                [valuePrimitive]="true"
                class="mri-k-show-validation"
              ></kendo-multiselect>
            </kendo-formfield>
          </div>
        </div>
        <mri-master-detail-header [noBackgroundColor]="true" title="Date range"></mri-master-detail-header>
        <div class="row">
          <kendo-formfield class="col-lg-12">
            <kendo-label for="Select" text="Select" class="text-right"></kendo-label>
            <ul class="k-input-list k-input-list--horizontal">
              <li>
                <input #all kendoRadioButton type="radio" [value]="true" formControlName="allDates" />
                <kendo-label [for]="all" class="k-radio-label" text="All"></kendo-label>
              </li>
              <li>
                <input #custom kendoRadioButton type="radio" [value]="false" formControlName="allDates" />
                <kendo-label [for]="custom" class="k-radio-label" text="Custom"></kendo-label>
              </li>
            </ul>
          </kendo-formfield>
        </div>
        <div class="row">
          <kendo-formfield class="col-lg-12">
            <kendo-label [for]="fromDate" text="Start date" class="text-right"></kendo-label>
            <kendo-datepicker
              #fromDate
              formControlName="fromDate"
              mriAgKendoRequired
              mriIeKendoDateFormat
              class="mri-k-show-validation"
            ></kendo-datepicker>
          </kendo-formfield>
        </div>
        <div class="row">
          <kendo-formfield class="col-lg-12">
            <kendo-label [for]="toDate" text="End date" class="text-right"></kendo-label>
            <kendo-datepicker
              #toDate
              formControlName="toDate"
              mriAgKendoRequired
              mriIeKendoDateFormat
              class="mri-k-show-validation"
            ></kendo-datepicker>
          </kendo-formfield>
        </div>
      </form>
    </div>
    <mri-shared-drawer-footer-toolbar
      *mriSharedDrawerFooter
      [canCancel]="true"
      [canSave]="vm.isValid"
      (cancel)="closeDrawer.next()"
      saveLabel="Download"
      (save)="save()"
      saveIcon="download"
    ></mri-shared-drawer-footer-toolbar>
  </mri-shared-drawer>
</ng-container>
