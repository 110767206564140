const DASH_ARRAYS = {
  dot: [1.5, 3.5],
  dash: [4, 3.5],
  longdash: [8, 3.5],
  dashdot: [3.5, 3.5, 1.5, 3.5],
  longdashdot: [8, 3.5, 1.5, 3.5],
  longdashdotdot: [8, 3.5, 1.5, 3.5, 1.5, 3.5]
};
const SOLID = "solid";
const BUTT = "butt";
const PATTERN = "Pattern";
export { DASH_ARRAYS, SOLID, BUTT, PATTERN };