import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DropdownOptions, UserPreference, UserPreferenceEntityService } from '@mri-platform/import-export/common-state';
import { connectForm, FormModel, FormState } from '@mri-platform/shared/common-ui';
import { keysOf, taggedNamespace } from '@mri-platform/shared/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { RxState } from '@rx-angular/state';
import { selectSlice } from '@rx-angular/state/selections';
import { range } from 'lodash';
import { Observable, shareReplay, tap } from 'rxjs';
import { LocalizationService } from '../../services/Localization.service';

/// Localization config
const textLocaleOptions = ['es-MX', 'en-GB', 'en-us', 'es-CL', 'ja-JP', 'ko-KR', 'pl-PL', 'th-TH', 'zh-CN', 'es-ES'];
const dateLocaleOptions = ['en-GB', 'en-us', 'es-CL', 'es-MX', 'ja-JP', 'ko-KR', 'pl-PL', 'th-TH', 'zh-CN'];
const numericLocaleOptions = ['en-GB', 'en-us', 'es-CL', 'es-MX', 'ja-JP', 'ko-KR', 'pl-PL', 'th-TH', 'zh-CN'];
const timeFormatOptions = [
  { text: '12-Hour Clock', value: 'h:mm a' },
  { text: '24-Hour Clock', value: 'HH:mm' }
];
const timeOffsetOptions = range(-23.5, 24, 0.5);
const phoneFormatOptions = [
  { text: 'On', value: true },
  { text: 'Off', value: true }
];

const initialFormState: FormModel<UserPreference> = {
  textLocale: ['en-us', [Validators.required]],
  numericLocale: [undefined, [Validators.required]],
  dateLocale: [undefined, [Validators.required]],
  timeFormat: [undefined, [Validators.required]],
  timeOffset: [undefined, [Validators.required]],
  phoneFormat: ['on', [Validators.required]]
};

interface ComponentState extends FormState<UserPreference> {
  textLocaleOptions: DropdownOptions[];
  dateLocaleOptions: DropdownOptions[];
  numericLocaleOptions: DropdownOptions[];
  timeFormatOptions: DropdownOptions[];
  timeOffsetOptions: DropdownOptions[];
  phoneFormatOptions: DropdownOptions[];
  isLoading: boolean;
}

type PublicState = Pick<
  ComponentState,
  | 'textLocaleOptions'
  | 'dateLocaleOptions'
  | 'numericLocaleOptions'
  | 'timeFormatOptions'
  | 'timeOffsetOptions'
  | 'phoneFormatOptions'
  | 'isDirty'
  | 'isValid'
  | 'isLoading'
>;

// ALL the fields that are accessed from the html template
type ViewModel = PublicState;

// the intial values for the fields defined in PublicState
const initialPublicState: PublicState = {
  textLocaleOptions: [],
  dateLocaleOptions: [],
  numericLocaleOptions: [],
  timeFormatOptions: [],
  timeOffsetOptions: [],
  phoneFormatOptions: [],
  isLoading: false,
  isDirty: false,
  isValid: false
};

const initialState: ComponentState = {
  ...initialPublicState,
  model: undefined as never
};

@Component({
  selector: 'mri-ie-user-preference-dialog',
  templateUrl: './user-preference-dialog.component.html',
  styleUrl: './user-preference-dialog.component.scss',
  providers: [RxState],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserPreferenceDialogComponent extends DialogContentBase {
  @HostBinding('attr.data-testid') testId = 'UserPreferenceDialogComponent';

  get model() {
    return this.state.get('model');
  }

  form = this.createForm();
  vm$: Observable<ViewModel>;

  today = new Date();

  constructor(
    private state: RxState<ComponentState>,
    private fb: FormBuilder,
    private localizationService: LocalizationService,
    private userPreferenceEntityService: UserPreferenceEntityService,
    public dialog: DialogRef
  ) {
    super(dialog);
    const tag = taggedNamespace(this.testId);

    // Set initial state in RxState
    this.state.set(initialState);

    // Connect any observable-driven items to state...
    this.state.set({
      textLocaleOptions: this.localizationService.getLocalOptions(textLocaleOptions),
      numericLocaleOptions: this.localizationService.getLocalOptions(numericLocaleOptions),
      dateLocaleOptions: this.localizationService.getLocalOptions(dateLocaleOptions),
      timeFormatOptions,
      timeOffsetOptions: timeOffsetOptions.map(v => {
        return { text: v.toString(), value: v };
      }),
      phoneFormatOptions
    });
    this.setInitialValue();

    this.state.connect('isLoading', this.userPreferenceEntityService.loading$);
    connectForm(this.form, this.state);

    // Create ViewModel (Projections + PublicState)...
    const publicState$ = this.state.select(selectSlice(keysOf(initialPublicState)));
    this.vm$ = publicState$.pipe(tag('vm'), shareReplay({ refCount: true, bufferSize: 1 }));
  }

  private setInitialValue() {
    this.state.set({ model: this.localizationService.currentUserPreference$.value });
    this.form.patchValue(this.model);
  }

  private createForm() {
    return this.fb.group(initialFormState);
  }

  save() {
    this.state.hold(this.userPreferenceEntityService.save(this.model).pipe(tap(_ => this.close())));
  }

  close() {
    this.dialog.close();
  }
}
