/**-----------------------------------------------------------------------------------------
* Copyright © 2024 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
var setData = require('@progress/kendo-angular-intl').setData;
setData({
  name: "ja",
  likelySubtags: {
    ja: "ja-Jpan-JP"
  },
  identity: {
    language: "ja"
  },
  territory: "JP",
  calendar: {
    patterns: {
      d: "y/MM/dd",
      D: "y年M月d日EEEE",
      m: "M月d日",
      M: "M月d日",
      y: "y年M月",
      Y: "y年M月",
      F: "y年M月d日EEEE H:mm:ss",
      g: "y/M/d H:mm",
      G: "y/M/d H:mm:ss",
      t: "H:mm",
      T: "H:mm:ss",
      s: "yyyy'-'MM'-'dd'T'HH':'mm':'ss",
      u: "yyyy'-'MM'-'dd HH':'mm':'ss'Z'"
    },
    dateTimeFormats: {
      full: "{1} {0}",
      long: "{1} {0}",
      medium: "{1} {0}",
      short: "{1} {0}",
      availableFormats: {
        Bh: "BK時",
        Bhm: "BK:mm",
        Bhms: "BK:mm:ss",
        d: "d日",
        E: "ccc",
        EBhm: "BK:mm (E)",
        EBhms: "BK:mm:ss (E)",
        Ed: "d日(E)",
        EEEEd: "d日EEEE",
        Ehm: "aK:mm (E)",
        EHm: "H:mm (E)",
        Ehms: "aK:mm:ss (E)",
        EHms: "H:mm:ss (E)",
        Gy: "Gy年",
        GyMd: "Gy/M/d",
        GyMMM: "Gy年M月",
        GyMMMd: "Gy年M月d日",
        GyMMMEd: "Gy年M月d日(E)",
        GyMMMEEEEd: "Gy年M月d日EEEE",
        h: "aK時",
        H: "H時",
        hm: "aK:mm",
        Hm: "H:mm",
        hms: "aK:mm:ss",
        Hms: "H:mm:ss",
        hmsv: "aK:mm:ss v",
        Hmsv: "H:mm:ss v",
        hmv: "aK:mm v",
        Hmv: "H:mm v",
        M: "M月",
        Md: "M/d",
        MEd: "M/d(E)",
        MEEEEd: "M/dEEEE",
        MMM: "M月",
        MMMd: "M月d日",
        MMMEd: "M月d日(E)",
        MMMEEEEd: "M月d日EEEE",
        MMMMd: "M月d日",
        "MMMMW-count-other": "M月第W週",
        ms: "mm:ss",
        y: "y年",
        yM: "y/M",
        yMd: "y/M/d",
        yMEd: "y/M/d(E)",
        yMEEEEd: "y/M/dEEEE",
        yMM: "y/MM",
        yMMM: "y年M月",
        yMMMd: "y年M月d日",
        yMMMEd: "y年M月d日(E)",
        yMMMEEEEd: "y年M月d日EEEE",
        yMMMM: "y年M月",
        yQQQ: "y/QQQ",
        yQQQQ: "y年QQQQ",
        "yw-count-other": "Y年第w週"
      }
    },
    timeFormats: {
      full: "H時mm分ss秒 zzzz",
      long: "H:mm:ss z",
      medium: "H:mm:ss",
      short: "H:mm"
    },
    dateFormats: {
      full: "y年M月d日EEEE",
      long: "y年M月d日",
      medium: "y/MM/dd",
      short: "y/MM/dd"
    },
    days: {
      format: {
        abbreviated: ["日", "月", "火", "水", "木", "金", "土"],
        narrow: ["日", "月", "火", "水", "木", "金", "土"],
        short: ["日", "月", "火", "水", "木", "金", "土"],
        wide: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"]
      },
      "stand-alone": {
        abbreviated: ["日", "月", "火", "水", "木", "金", "土"],
        narrow: ["日", "月", "火", "水", "木", "金", "土"],
        short: ["日", "月", "火", "水", "木", "金", "土"],
        wide: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"]
      }
    },
    months: {
      format: {
        abbreviated: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
        narrow: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
        wide: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"]
      },
      "stand-alone": {
        abbreviated: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
        narrow: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
        wide: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"]
      }
    },
    quarters: {
      format: {
        abbreviated: ["Q1", "Q2", "Q3", "Q4"],
        narrow: ["1", "2", "3", "4"],
        wide: ["第1四半期", "第2四半期", "第3四半期", "第4四半期"]
      },
      "stand-alone": {
        abbreviated: ["Q1", "Q2", "Q3", "Q4"],
        narrow: ["1", "2", "3", "4"],
        wide: ["第1四半期", "第2四半期", "第3四半期", "第4四半期"]
      }
    },
    dayPeriods: {
      format: {
        abbreviated: {
          midnight: "真夜中",
          am: "午前",
          noon: "正午",
          pm: "午後",
          morning1: "朝",
          afternoon1: "昼",
          evening1: "夕方",
          night1: "夜",
          night2: "夜中"
        },
        narrow: {
          midnight: "真夜中",
          am: "午前",
          noon: "正午",
          pm: "午後",
          morning1: "朝",
          afternoon1: "昼",
          evening1: "夕方",
          night1: "夜",
          night2: "夜中"
        },
        wide: {
          midnight: "真夜中",
          am: "午前",
          noon: "正午",
          pm: "午後",
          morning1: "朝",
          afternoon1: "昼",
          evening1: "夕方",
          night1: "夜",
          night2: "夜中"
        }
      },
      "stand-alone": {
        abbreviated: {
          midnight: "真夜中",
          am: "午前",
          noon: "正午",
          pm: "午後",
          morning1: "朝",
          afternoon1: "昼",
          evening1: "夕方",
          night1: "夜",
          night2: "夜中"
        },
        narrow: {
          midnight: "真夜中",
          am: "午前",
          noon: "正午",
          pm: "午後",
          morning1: "朝",
          afternoon1: "昼",
          evening1: "夕方",
          night1: "夜",
          night2: "夜中"
        },
        wide: {
          midnight: "真夜中",
          am: "午前",
          noon: "正午",
          pm: "午後",
          morning1: "朝",
          afternoon1: "昼",
          evening1: "夕方",
          night1: "夜",
          night2: "夜中"
        }
      }
    },
    eras: {
      format: {
        wide: {
          "0": "紀元前",
          "1": "西暦",
          "0-alt-variant": "西暦紀元前",
          "1-alt-variant": "西暦紀元"
        },
        abbreviated: {
          "0": "紀元前",
          "1": "西暦",
          "0-alt-variant": "西暦紀元前",
          "1-alt-variant": "西暦紀元"
        },
        narrow: {
          "0": "BC",
          "1": "AD",
          "0-alt-variant": "西暦紀元前",
          "1-alt-variant": "西暦紀元"
        }
      }
    },
    gmtFormat: "GMT{0}",
    gmtZeroFormat: "GMT",
    dateFields: {
      era: {
        wide: "時代",
        short: "時代",
        narrow: "時代"
      },
      year: {
        wide: "年",
        short: "年",
        narrow: "年"
      },
      quarter: {
        wide: "四半期",
        short: "四半期",
        narrow: "四半期"
      },
      month: {
        wide: "月",
        short: "月",
        narrow: "月"
      },
      week: {
        wide: "週",
        short: "週",
        narrow: "週"
      },
      weekOfMonth: {
        wide: "月の週番号",
        short: "月の週番号",
        narrow: "月の週番号"
      },
      day: {
        wide: "日",
        short: "日",
        narrow: "日"
      },
      dayOfYear: {
        wide: "年の通日",
        short: "年の通日",
        narrow: "通日"
      },
      weekday: {
        wide: "曜日",
        short: "曜日",
        narrow: "曜日"
      },
      weekdayOfMonth: {
        wide: "月の曜日番号",
        short: "月の曜日番号",
        narrow: "月の曜日番号"
      },
      dayperiod: {
        short: "午前/午後",
        wide: "午前/午後",
        narrow: "午前/午後"
      },
      hour: {
        wide: "時",
        short: "時",
        narrow: "時"
      },
      minute: {
        wide: "分",
        short: "分",
        narrow: "分"
      },
      second: {
        wide: "秒",
        short: "秒",
        narrow: "秒"
      },
      zone: {
        wide: "タイムゾーン",
        short: "タイムゾーン",
        narrow: "タイムゾーン"
      },
      millisecond: {
        narrow: "ms",
        short: "ms",
        wide: "ミリ秒"
      }
    }
  },
  firstDay: 0
});