import { Type } from '@angular/core';
import { ActivatedRoute, Event } from '@angular/router';
import { Observable } from 'rxjs';
import { map, shareReplay, startWith } from 'rxjs/operators';
import { RouteComponentSelector, routerActivationEndForComponent } from './router-activation-end-for-component';

type EntityIdParser = ((value: string) => string) | ((value: string) => number);

export function routerCurrentChildEntityId(
  route: ActivatedRoute,
  childComponentType: Type<unknown> | RouteComponentSelector,
  parser: EntityIdParser = (value: string) => value,
  idParamName = 'id'
) {
  return (source$: Observable<Event>) =>
    source$.pipe(
      routerActivationEndForComponent(childComponentType, route),
      map(({ snapshot }) => snapshot.paramMap.get(idParamName) ?? null),
      map(id => (id !== null ? parser(id) : id)),
      startWith(null),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
}
