export const Key = {
  DELETE: "Delete",
  BACKSPACE: "Backspace",
  TAB: "Tab",
  ENTER: "Enter",
  ESCAPE: "Escape",
  ARROW_LEFT: "ArrowLeft",
  ARROW_UP: "ArrowUp",
  ARROW_RIGHT: "ArrowRight",
  ARROW_DOWN: "ArrowDown",
  SPACE: " ",
  END: "End",
  HOME: "Home",
  PAGE_UP: "PageUp",
  PAGE_DOWN: "PageDown"
};