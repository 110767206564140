import { HttpHeaders } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ErrorPolicyService, muteError } from '@mri-platform/angular-error-handling';
import { File, FileSelectorFormData, FileTypeModel, SourceType } from '@mri-platform/import-export/common-state';
import { FormModel, FormState, connectForm, enableControl } from '@mri-platform/shared/common-ui';
import { ApiConfig } from '@mri-platform/shared/core';
import { AuthService } from '@mri/angular-wfe-proxy-oidc';
import { ErrorEvent, FileRestrictions, SuccessEvent, UploadComponent } from '@progress/kendo-angular-upload';
import { RxState } from '@rx-angular/state';
import { BehaviorSubject, Observable, asapScheduler, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, observeOn, startWith, tap } from 'rxjs/operators';

const initialFormState: FormModel<FileSelectorFormData> = {
  id: [''],
  name: ['', Validators.required],
  metaData: [null],
  fileUploaded: [null],
  fileSelected: [false],
  sheetName: ['', Validators.required]
};

type ComponentState = FormState<FileSelectorFormData> & {
  workSheets: string[];
};

type PublicState = Pick<ComponentState, 'workSheets'>;

interface Projections {
  showWorksheetDropdown: boolean;
}

type ViewModel = PublicState & Projections;

const initialPublicState: PublicState = {
  workSheets: []
};

const initialState: ComponentState = {
  ...initialPublicState,
  isDirty: false,
  isValid: false,
  model: undefined as never
};

@Component({
  selector: 'mri-ie-connection-file-picker',
  templateUrl: './connection-file-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: block;
      }
    `
  ],
  providers: [RxState]
})
export class ConnectionFilePickerComponent {
  @HostBinding('attr.data-testid') testId = 'ConnectionFilePickerComponent';

  @Input() title = 'Source';

  @Input() set model(value: FileSelectorFormData) {
    if (!value) {
      return;
    }
    const { id, name, fileUploaded, metaData, fileSelected, sheetName } = value;
    if (fileUploaded) {
      this.form.patchValue({ id, name, fileUploaded, metaData, fileSelected, sheetName });
      if (sheetName) this.form.controls.sheetName.markAsDirty();
    }
  }

  @Input() api = `${this.apiConfig.url}/files?isMetadataRequired=true`;

  @Input() set fileTypeSelected(value: FileTypeModel) {
    if (value && value.fileType) {
      this.fileRestrictions = { ...this.fileRestrictions, allowedExtensions: [`.${value.fileType}`] };
    }
  }

  @Input() set isFileSizeRestricted(isFileSizeRestricted: boolean) {
    if (isFileSizeRestricted) {
      this.fileRestrictions = { ...this.fileRestrictions, maxFileSize: 1000000 };
    }
  }

  @Input() filePickerEnableStatus = true;
  fileRestrictions: FileRestrictions = {
    allowedExtensions: ['.csv', '.xlsx']
  };

  @Input() showWorksheetSelection = false;

  uploadHeaders: HttpHeaders;

  @Output() dirtyChanges = this.state.select('isDirty').pipe(observeOn(asapScheduler));
  @Output() validChanges = this.state.select('isValid').pipe(observeOn(asapScheduler));
  @Output() valueChanges = this.state.select('model');

  @ViewChild(UploadComponent) uploadComponent!: UploadComponent;

  sourceSelection = new BehaviorSubject<SourceType>(SourceType.FILE);

  vm$: Observable<ViewModel>;

  constructor(
    private fb: FormBuilder,
    private state: RxState<ComponentState>,
    private errorPolicy: ErrorPolicyService,
    private apiConfig: ApiConfig,
    private authService: AuthService
  ) {
    this.uploadHeaders = new HttpHeaders().set('Authorization', `Bearer ${this.authService.getAccessToken()}`);

    this.state.set(initialState);
    connectForm(this.form, this.state, { tagPrefix: this.testId });

    const showWorksheetDropdown$ = this.state.select('model').pipe(
      map(v => v.metaData?.contentType == 'Xlsx' && this.showWorksheetSelection),
      distinctUntilChanged(),
      tap(enable => {
        if (!enable) {
          this.form.controls.sheetName.reset();
        }
        enableControl(this.form.controls.sheetName, enable);
      }),
      startWith(false)
    );

    const workSheets$ = this.state.select('model').pipe(
      map(m => m.metaData?.worksheets ?? []),
      startWith([]),
      tap(sheets => {
        if (sheets.length === 1) {
          this.form.controls.sheetName.patchValue(sheets[0]);
          this.form.controls.sheetName.markAsDirty();
        }
      })
    );

    this.vm$ = combineLatest([this.state.select(), showWorksheetDropdown$, workSheets$]).pipe(
      map(
        ([componentState, showWorksheetDropdown, workSheets]): ViewModel => ({
          ...componentState,
          showWorksheetDropdown,
          workSheets
        })
      )
    );
  }

  form = this.createForm();

  private createForm() {
    return this.fb.group(initialFormState);
  }

  uploadSuccess(data: SuccessEvent) {
    const { name, id, metaData } = data.response.body as File;
    this.form.patchValue({ name, id, metaData });
  }

  uploadFailure(e: ErrorEvent) {
    this.errorPolicy.log(e.response, muteError);
  }

  toggleFileSelection(value: boolean) {
    this.form.patchValue({ fileSelected: value });
  }

  fileCleared() {
    this.form.patchValue({ name: '', id: '', metaData: null, fileSelected: false, sheetName: null });
  }

  getInstance() {
    return this.uploadComponent;
  }
}
