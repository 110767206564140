/**-----------------------------------------------------------------------------------------
* Copyright © 2024 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
var setData = require('@progress/kendo-angular-intl').setData;
setData({
  name: "th",
  likelySubtags: {
    th: "th-Thai-TH"
  },
  identity: {
    language: "th"
  },
  territory: "TH",
  calendar: {
    patterns: {
      d: "d/M/y",
      D: "EEEEที่ d MMMM G y",
      m: "d MMM",
      M: "d MMMM",
      y: "MMM y",
      Y: "MMMM G y",
      F: "EEEEที่ d MMMM G y HH:mm:ss",
      g: "d/M/y HH:mm",
      G: "d/M/y HH:mm:ss",
      t: "HH:mm",
      T: "HH:mm:ss",
      s: "yyyy'-'MM'-'dd'T'HH':'mm':'ss",
      u: "yyyy'-'MM'-'dd HH':'mm':'ss'Z'"
    },
    dateTimeFormats: {
      full: "{1} {0}",
      long: "{1} {0}",
      medium: "{1} {0}",
      short: "{1} {0}",
      availableFormats: {
        Bh: "h B",
        Bhm: "h:mm B",
        Bhms: "h:mm:ss B",
        d: "d",
        E: "ccc",
        EBhm: "E h:mm B",
        EBhms: "E h:mm:ss B",
        Ed: "E d",
        Ehm: "E h:mm a",
        EHm: "E HH:mm น.",
        Ehms: "E h:mm:ss a",
        EHms: "E HH:mm:ss",
        Gy: "G y",
        GyMd: "d/M/GGGGG y",
        GyMMM: "MMM G y",
        GyMMMd: "d MMM G y",
        GyMMMEd: "E d MMM G y",
        GyMMMEEEEd: "EEEEที่ d MMM G y",
        h: "h a",
        H: "HH",
        hm: "h:mm a",
        Hm: "HH:mm น.",
        hms: "h:mm:ss a",
        Hms: "HH:mm:ss",
        hmsv: "h:mm:ss a v",
        Hmsv: "HH:mm:ss v",
        hmv: "h:mm น. a v",
        Hmv: "HH:mm v",
        M: "L",
        Md: "d/M",
        MEd: "E d/M",
        MMM: "LLL",
        MMMd: "d MMM",
        MMMEd: "E d MMM",
        MMMEEEEd: "EEEEที่ d MMM",
        MMMMd: "d MMMM",
        MMMMEd: "E d MMMM",
        MMMMEEEEd: "EEEEที่ d MMMM",
        "MMMMW-count-other": "สัปดาห์ที่ W ของเดือนMMMM",
        mmss: "mm:ss",
        ms: "mm:ss",
        y: "y",
        yM: "M/y",
        yMd: "d/M/y",
        yMEd: "E d/M/y",
        yMMM: "MMM y",
        yMMMd: "d MMM y",
        yMMMEd: "E d MMM y",
        yMMMEEEEd: "EEEEที่ d MMM y",
        yMMMM: "MMMM G y",
        yMMMMd: "d MMMM G y",
        yMMMMEd: "E d MMMM G y",
        yMMMMEEEEd: "EEEEที่ d MMMM G y",
        yQQQ: "QQQ y",
        yQQQQ: "QQQQ G y",
        "yw-count-other": "สัปดาห์ที่ w ของปี Y"
      }
    },
    timeFormats: {
      full: "H นาฬิกา mm นาที ss วินาที zzzz",
      long: "H นาฬิกา mm นาที ss วินาที z",
      medium: "HH:mm:ss",
      short: "HH:mm"
    },
    dateFormats: {
      full: "EEEEที่ d MMMM G y",
      long: "d MMMM G y",
      medium: "d MMM y",
      short: "d/M/yy"
    },
    days: {
      format: {
        abbreviated: ["อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส."],
        narrow: ["อา", "จ", "อ", "พ", "พฤ", "ศ", "ส"],
        short: ["อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส."],
        wide: ["วันอาทิตย์", "วันจันทร์", "วันอังคาร", "วันพุธ", "วันพฤหัสบดี", "วันศุกร์", "วันเสาร์"]
      },
      "stand-alone": {
        abbreviated: ["อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส."],
        narrow: ["อา", "จ", "อ", "พ", "พฤ", "ศ", "ส"],
        short: ["อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส."],
        wide: ["วันอาทิตย์", "วันจันทร์", "วันอังคาร", "วันพุธ", "วันพฤหัสบดี", "วันศุกร์", "วันเสาร์"]
      }
    },
    months: {
      format: {
        abbreviated: ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."],
        narrow: ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."],
        wide: ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"]
      },
      "stand-alone": {
        abbreviated: ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."],
        narrow: ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."],
        wide: ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"]
      }
    },
    quarters: {
      format: {
        abbreviated: ["ไตรมาส 1", "ไตรมาส 2", "ไตรมาส 3", "ไตรมาส 4"],
        narrow: ["1", "2", "3", "4"],
        wide: ["ไตรมาส 1", "ไตรมาส 2", "ไตรมาส 3", "ไตรมาส 4"]
      },
      "stand-alone": {
        abbreviated: ["ไตรมาส 1", "ไตรมาส 2", "ไตรมาส 3", "ไตรมาส 4"],
        narrow: ["1", "2", "3", "4"],
        wide: ["ไตรมาส 1", "ไตรมาส 2", "ไตรมาส 3", "ไตรมาส 4"]
      }
    },
    dayPeriods: {
      format: {
        abbreviated: {
          midnight: "เที่ยงคืน",
          am: "ก่อนเที่ยง",
          noon: "เที่ยง",
          pm: "หลังเที่ยง",
          morning1: "ในตอนเช้า",
          afternoon1: "ในตอนบ่าย",
          afternoon2: "บ่าย",
          evening1: "ในตอนเย็น",
          evening2: "ค่ำ",
          night1: "กลางคืน"
        },
        narrow: {
          midnight: "เที่ยงคืน",
          am: "a",
          noon: "เที่ยง",
          pm: "p",
          morning1: "เช้า",
          afternoon1: "เที่ยง",
          afternoon2: "บ่าย",
          evening1: "เย็น",
          evening2: "ค่ำ",
          night1: "กลางคืน"
        },
        wide: {
          midnight: "เที่ยงคืน",
          am: "ก่อนเที่ยง",
          noon: "เที่ยง",
          pm: "หลังเที่ยง",
          morning1: "ในตอนเช้า",
          afternoon1: "ในตอนบ่าย",
          afternoon2: "บ่าย",
          evening1: "ในตอนเย็น",
          evening2: "ค่ำ",
          night1: "กลางคืน"
        }
      },
      "stand-alone": {
        abbreviated: {
          midnight: "เที่ยงคืน",
          am: "ก่อนเที่ยง",
          noon: "เที่ยง",
          pm: "หลังเที่ยง",
          morning1: "ในตอนเช้า",
          afternoon1: "ในตอนบ่าย",
          afternoon2: "บ่าย",
          evening1: "ในตอนเย็น",
          evening2: "ค่ำ",
          night1: "กลางคืน"
        },
        narrow: {
          midnight: "เที่ยงคืน",
          am: "ก่อนเที่ยง",
          noon: "เที่ยง",
          pm: "หลังเที่ยง",
          morning1: "เช้า",
          afternoon1: "ช่วงเที่ยง",
          afternoon2: "บ่าย",
          evening1: "เย็น",
          evening2: "ค่ำ",
          night1: "กลางคืน"
        },
        wide: {
          midnight: "เที่ยงคืน",
          am: "ก่อนเที่ยง",
          noon: "เที่ยง",
          pm: "หลังเที่ยง",
          morning1: "ในตอนเช้า",
          afternoon1: "ในตอนบ่าย",
          afternoon2: "บ่าย",
          evening1: "ในตอนเย็น",
          evening2: "ค่ำ",
          night1: "กลางคืน"
        }
      }
    },
    eras: {
      format: {
        wide: {
          "0": "ปีก่อนคริสตกาล",
          "1": "คริสต์ศักราช",
          "0-alt-variant": "ก่อนสามัญศักราช",
          "1-alt-variant": "สามัญศักราช"
        },
        abbreviated: {
          "0": "ก่อน ค.ศ.",
          "1": "ค.ศ.",
          "0-alt-variant": "ก่อน ส.ศ.",
          "1-alt-variant": "ส.ศ."
        },
        narrow: {
          "0": "ก่อน ค.ศ.",
          "1": "ค.ศ.",
          "0-alt-variant": "ก.ส.ศ.",
          "1-alt-variant": "ส.ศ."
        }
      }
    },
    gmtFormat: "GMT{0}",
    gmtZeroFormat: "GMT",
    dateFields: {
      era: {
        wide: "สมัย",
        short: "สมัย",
        narrow: "สมัย"
      },
      year: {
        wide: "ปี",
        short: "ปี",
        narrow: "ปี"
      },
      quarter: {
        wide: "ไตรมาส",
        short: "ไตรมาส",
        narrow: "ไตรมาส"
      },
      month: {
        wide: "เดือน",
        short: "เดือน",
        narrow: "เดือน"
      },
      week: {
        wide: "สัปดาห์",
        short: "สัปดาห์",
        narrow: "สัปดาห์"
      },
      weekOfMonth: {
        wide: "สัปดาห์ของเดือน",
        short: "สัปดาห์ของเดือน",
        narrow: "สัปดาห์ของเดือน"
      },
      day: {
        wide: "วัน",
        short: "วัน",
        narrow: "วัน"
      },
      dayOfYear: {
        wide: "วันของปี",
        short: "วันของปี",
        narrow: "วันของปี"
      },
      weekday: {
        wide: "วันของสัปดาห์",
        short: "วันของสัปดาห์",
        narrow: "วันของสัปดาห์"
      },
      weekdayOfMonth: {
        wide: "วันของเดือน",
        short: "วันของเดือน",
        narrow: "วันของเดือน"
      },
      dayperiod: {
        short: "ช่วงวัน",
        wide: "ช่วงวัน",
        narrow: "ช่วงวัน"
      },
      hour: {
        wide: "ชั่วโมง",
        short: "ชม.",
        narrow: "ชม."
      },
      minute: {
        wide: "นาที",
        short: "นาที",
        narrow: "นาที"
      },
      second: {
        wide: "วินาที",
        short: "วิ",
        narrow: "วิ"
      },
      zone: {
        wide: "เขตเวลา",
        short: "โซน",
        narrow: "เขตเวลา"
      },
      millisecond: {
        narrow: "มิลลิวิ",
        short: "มิลลิวินาที",
        wide: "มิลลิวินาที"
      }
    }
  },
  firstDay: 0
});