/**-----------------------------------------------------------------------------------------
* Copyright © 2024 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
var setData = require('@progress/kendo-angular-intl').setData;
setData({
  name: "zh",
  likelySubtags: {
    zh: "zh-Hans-CN"
  },
  identity: {
    language: "zh"
  },
  territory: "CN",
  calendar: {
    patterns: {
      d: "y/M/d",
      D: "y年M月d日EEEE",
      m: "M月d日",
      M: "M月d日",
      y: "y年M月",
      Y: "y年M月",
      F: "y年M月d日EEEE HH:mm:ss",
      g: "y/M/d HH:mm",
      G: "y/M/d HH:mm:ss",
      t: "HH:mm",
      T: "HH:mm:ss",
      s: "yyyy'-'MM'-'dd'T'HH':'mm':'ss",
      u: "yyyy'-'MM'-'dd HH':'mm':'ss'Z'"
    },
    dateTimeFormats: {
      full: "{1} {0}",
      long: "{1} {0}",
      medium: "{1} {0}",
      short: "{1} {0}",
      availableFormats: {
        Bh: "Bh时",
        Bhm: "Bh:mm",
        Bhms: "Bh:mm:ss",
        d: "d日",
        E: "ccc",
        EBhm: "EBh:mm",
        EBhms: "EBh:mm:ss",
        Ed: "d日E",
        Ehm: "Eah:mm",
        EHm: "EHH:mm",
        Ehms: "Eah:mm:ss",
        EHms: "EHH:mm:ss",
        Gy: "Gy年",
        GyMd: "GGGGG y-MM-dd",
        GyMMM: "Gy年M月",
        GyMMMd: "Gy年M月d日",
        GyMMMEd: "Gy年M月d日E",
        h: "ah时",
        H: "H时",
        hm: "ah:mm",
        Hm: "HH:mm",
        hms: "ah:mm:ss",
        Hms: "HH:mm:ss",
        hmsv: "v ah:mm:ss",
        Hmsv: "v HH:mm:ss",
        hmv: "v ah:mm",
        Hmv: "v HH:mm",
        M: "M月",
        Md: "M/d",
        MEd: "M/dE",
        MMdd: "MM/dd",
        MMM: "LLL",
        MMMd: "M月d日",
        MMMEd: "M月d日E",
        MMMMd: "M月d日",
        "MMMMW-count-other": "MMMM第W周",
        ms: "mm:ss",
        y: "y年",
        yM: "y年M月",
        yMd: "y/M/d",
        yMEd: "y/M/dE",
        yMEEEEd: "y年M月d日EEEE",
        yMM: "y年M月",
        yMMM: "y年M月",
        yMMMd: "y年M月d日",
        yMMMEd: "y年M月d日E",
        yMMMM: "y年M月",
        yQQQ: "y年第Q季度",
        yQQQQ: "y年第Q季度",
        "yw-count-other": "Y年第w周"
      }
    },
    timeFormats: {
      full: "zzzz HH:mm:ss",
      long: "z HH:mm:ss",
      medium: "HH:mm:ss",
      short: "HH:mm"
    },
    dateFormats: {
      full: "y年M月d日EEEE",
      long: "y年M月d日",
      medium: "y年M月d日",
      short: "y/M/d"
    },
    days: {
      format: {
        abbreviated: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
        narrow: ["日", "一", "二", "三", "四", "五", "六"],
        short: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
        wide: ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"]
      },
      "stand-alone": {
        abbreviated: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
        narrow: ["日", "一", "二", "三", "四", "五", "六"],
        short: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
        wide: ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"]
      }
    },
    months: {
      format: {
        abbreviated: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
        narrow: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
        wide: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"]
      },
      "stand-alone": {
        abbreviated: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
        narrow: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
        wide: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"]
      }
    },
    quarters: {
      format: {
        abbreviated: ["1季度", "2季度", "3季度", "4季度"],
        narrow: ["1", "2", "3", "4"],
        wide: ["第一季度", "第二季度", "第三季度", "第四季度"]
      },
      "stand-alone": {
        abbreviated: ["1季度", "2季度", "3季度", "4季度"],
        narrow: ["1", "2", "3", "4"],
        wide: ["第一季度", "第二季度", "第三季度", "第四季度"]
      }
    },
    dayPeriods: {
      format: {
        abbreviated: {
          midnight: "午夜",
          am: "上午",
          pm: "下午",
          morning1: "早上",
          morning2: "上午",
          afternoon1: "中午",
          afternoon2: "下午",
          evening1: "晚上",
          night1: "凌晨"
        },
        narrow: {
          midnight: "午夜",
          am: "上午",
          pm: "下午",
          morning1: "早上",
          morning2: "上午",
          afternoon1: "中午",
          afternoon2: "下午",
          evening1: "晚上",
          night1: "凌晨"
        },
        wide: {
          midnight: "午夜",
          am: "上午",
          pm: "下午",
          morning1: "清晨",
          morning2: "上午",
          afternoon1: "中午",
          afternoon2: "下午",
          evening1: "晚上",
          night1: "凌晨"
        }
      },
      "stand-alone": {
        abbreviated: {
          midnight: "午夜",
          am: "上午",
          pm: "下午",
          morning1: "早上",
          morning2: "上午",
          afternoon1: "中午",
          afternoon2: "下午",
          evening1: "晚上",
          night1: "凌晨"
        },
        narrow: {
          midnight: "午夜",
          am: "上午",
          pm: "下午",
          morning1: "早上",
          morning2: "上午",
          afternoon1: "中午",
          afternoon2: "下午",
          evening1: "晚上",
          night1: "凌晨"
        },
        wide: {
          midnight: "午夜",
          am: "上午",
          pm: "下午",
          morning1: "早上",
          morning2: "上午",
          afternoon1: "中午",
          afternoon2: "下午",
          evening1: "晚上",
          night1: "凌晨"
        }
      }
    },
    eras: {
      format: {
        wide: {
          "0": "公元前",
          "1": "公元",
          "0-alt-variant": "公元前",
          "1-alt-variant": "公元"
        },
        abbreviated: {
          "0": "公元前",
          "1": "公元",
          "0-alt-variant": "公元前",
          "1-alt-variant": "公元"
        },
        narrow: {
          "0": "公元前",
          "1": "公元",
          "0-alt-variant": "公元前",
          "1-alt-variant": "公元"
        }
      }
    },
    gmtFormat: "GMT{0}",
    gmtZeroFormat: "GMT",
    dateFields: {
      era: {
        wide: "纪元",
        short: "纪元",
        narrow: "纪元"
      },
      year: {
        wide: "年",
        short: "年",
        narrow: "年"
      },
      quarter: {
        wide: "季度",
        short: "季",
        narrow: "季"
      },
      month: {
        wide: "月",
        short: "月",
        narrow: "月"
      },
      week: {
        wide: "周",
        short: "周",
        narrow: "周"
      },
      weekOfMonth: {
        wide: "月中周",
        short: "月中周",
        narrow: "月中周"
      },
      day: {
        wide: "日",
        short: "日",
        narrow: "日"
      },
      dayOfYear: {
        wide: "年中日",
        short: "年中日",
        narrow: "年中日"
      },
      weekday: {
        wide: "工作日",
        short: "工作日",
        narrow: "工作日"
      },
      weekdayOfMonth: {
        wide: "月中日",
        short: "月中日",
        narrow: "月中日"
      },
      dayperiod: {
        short: "上午/下午",
        wide: "上午/下午",
        narrow: "上午/下午"
      },
      hour: {
        wide: "小时",
        short: "小时",
        narrow: "小时"
      },
      minute: {
        wide: "分钟",
        short: "分",
        narrow: "分"
      },
      second: {
        wide: "秒",
        short: "秒",
        narrow: "秒"
      },
      zone: {
        wide: "时区",
        short: "时区",
        narrow: "时区"
      },
      millisecond: {
        narrow: "毫秒",
        short: "毫秒",
        wide: "毫秒"
      }
    }
  },
  firstDay: 0
});