/**-----------------------------------------------------------------------------------------
* Copyright © 2024 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
var setData = require('@progress/kendo-angular-intl').setData;
setData({
  name: "en",
  likelySubtags: {
    en: "en-Latn-US"
  },
  identity: {
    language: "en"
  },
  territory: "US",
  calendar: {
    gmtFormat: "GMT{0}",
    gmtZeroFormat: "GMT",
    patterns: {
      d: "M/d/y",
      D: "EEEE, MMMM d, y",
      m: "MMM d",
      M: "MMMM d",
      y: "MMM y",
      Y: "MMMM y",
      F: "EEEE, MMMM d, y h:mm:ss a",
      g: "M/d/y h:mm a",
      G: "M/d/y h:mm:ss a",
      t: "h:mm a",
      T: "h:mm:ss a",
      s: "yyyy'-'MM'-'dd'T'HH':'mm':'ss",
      u: "yyyy'-'MM'-'dd HH':'mm':'ss'Z'"
    },
    dateTimeFormats: {
      full: "{1} 'at' {0}",
      long: "{1} 'at' {0}",
      medium: "{1}, {0}",
      short: "{1}, {0}",
      availableFormats: {
        Bh: "h B",
        Bhm: "h:mm B",
        Bhms: "h:mm:ss B",
        d: "d",
        E: "ccc",
        EBhm: "E h:mm B",
        EBhms: "E h:mm:ss B",
        Ed: "d E",
        Ehm: "E h:mm a",
        EHm: "E HH:mm",
        Ehms: "E h:mm:ss a",
        EHms: "E HH:mm:ss",
        Gy: "y G",
        GyMd: "M/d/y GGGGG",
        GyMMM: "MMM y G",
        GyMMMd: "MMM d, y G",
        GyMMMEd: "E, MMM d, y G",
        h: "h a",
        H: "HH",
        hm: "h:mm a",
        Hm: "HH:mm",
        hms: "h:mm:ss a",
        Hms: "HH:mm:ss",
        hmsv: "h:mm:ss a v",
        Hmsv: "HH:mm:ss v",
        hmv: "h:mm a v",
        Hmv: "HH:mm v",
        M: "L",
        Md: "M/d",
        MEd: "E, M/d",
        MMM: "LLL",
        MMMd: "MMM d",
        MMMEd: "E, MMM d",
        MMMMd: "MMMM d",
        "MMMMW-count-one": "'week' W 'of' MMMM",
        "MMMMW-count-other": "'week' W 'of' MMMM",
        ms: "mm:ss",
        y: "y",
        yM: "M/y",
        yMd: "M/d/y",
        yMEd: "E, M/d/y",
        yMMM: "MMM y",
        yMMMd: "MMM d, y",
        yMMMEd: "E, MMM d, y",
        yMMMM: "MMMM y",
        yQQQ: "QQQ y",
        yQQQQ: "QQQQ y",
        "yw-count-one": "'week' w 'of' Y",
        "yw-count-other": "'week' w 'of' Y"
      }
    },
    timeFormats: {
      full: "h:mm:ss a zzzz",
      long: "h:mm:ss a z",
      medium: "h:mm:ss a",
      short: "h:mm a"
    },
    dateFormats: {
      full: "EEEE, MMMM d, y",
      long: "MMMM d, y",
      medium: "MMM d, y",
      short: "M/d/yy"
    },
    days: {
      format: {
        abbreviated: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        narrow: ["S", "M", "T", "W", "T", "F", "S"],
        short: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
        wide: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      },
      "stand-alone": {
        abbreviated: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        narrow: ["S", "M", "T", "W", "T", "F", "S"],
        short: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
        wide: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      }
    },
    months: {
      format: {
        abbreviated: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
        wide: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
      },
      "stand-alone": {
        abbreviated: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
        wide: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
      }
    },
    quarters: {
      format: {
        abbreviated: ["Q1", "Q2", "Q3", "Q4"],
        narrow: ["1", "2", "3", "4"],
        wide: ["1st quarter", "2nd quarter", "3rd quarter", "4th quarter"]
      },
      "stand-alone": {
        abbreviated: ["Q1", "Q2", "Q3", "Q4"],
        narrow: ["1", "2", "3", "4"],
        wide: ["1st quarter", "2nd quarter", "3rd quarter", "4th quarter"]
      }
    },
    dayPeriods: {
      format: {
        abbreviated: {
          midnight: "midnight",
          am: "AM",
          "am-alt-variant": "am",
          noon: "noon",
          pm: "PM",
          "pm-alt-variant": "pm",
          morning1: "in the morning",
          afternoon1: "in the afternoon",
          evening1: "in the evening",
          night1: "at night"
        },
        narrow: {
          midnight: "mi",
          am: "a",
          "am-alt-variant": "am",
          noon: "n",
          pm: "p",
          "pm-alt-variant": "pm",
          morning1: "in the morning",
          afternoon1: "in the afternoon",
          evening1: "in the evening",
          night1: "at night"
        },
        wide: {
          midnight: "midnight",
          am: "AM",
          "am-alt-variant": "am",
          noon: "noon",
          pm: "PM",
          "pm-alt-variant": "pm",
          morning1: "in the morning",
          afternoon1: "in the afternoon",
          evening1: "in the evening",
          night1: "at night"
        }
      },
      "stand-alone": {
        abbreviated: {
          midnight: "midnight",
          am: "AM",
          "am-alt-variant": "am",
          noon: "noon",
          pm: "PM",
          "pm-alt-variant": "pm",
          morning1: "morning",
          afternoon1: "afternoon",
          evening1: "evening",
          night1: "night"
        },
        narrow: {
          midnight: "midnight",
          am: "AM",
          "am-alt-variant": "am",
          noon: "noon",
          pm: "PM",
          "pm-alt-variant": "pm",
          morning1: "morning",
          afternoon1: "afternoon",
          evening1: "evening",
          night1: "night"
        },
        wide: {
          midnight: "midnight",
          am: "AM",
          "am-alt-variant": "am",
          noon: "noon",
          pm: "PM",
          "pm-alt-variant": "pm",
          morning1: "morning",
          afternoon1: "afternoon",
          evening1: "evening",
          night1: "night"
        }
      }
    },
    eras: {
      format: {
        wide: {
          "0": "Before Christ",
          "1": "Anno Domini",
          "0-alt-variant": "Before Common Era",
          "1-alt-variant": "Common Era"
        },
        abbreviated: {
          "0": "BC",
          "1": "AD",
          "0-alt-variant": "BCE",
          "1-alt-variant": "CE"
        },
        narrow: {
          "0": "B",
          "1": "A",
          "0-alt-variant": "BCE",
          "1-alt-variant": "CE"
        }
      }
    },
    dateFields: {
      era: {
        wide: "era",
        short: "era",
        narrow: "era"
      },
      year: {
        wide: "year",
        short: "yr.",
        narrow: "yr."
      },
      quarter: {
        wide: "quarter",
        short: "qtr.",
        narrow: "qtr."
      },
      month: {
        wide: "month",
        short: "mo.",
        narrow: "mo."
      },
      week: {
        wide: "week",
        short: "wk.",
        narrow: "wk."
      },
      weekOfMonth: {
        wide: "week of month",
        short: "wk. of mo.",
        narrow: "wk. of mo."
      },
      day: {
        wide: "day",
        short: "day",
        narrow: "day"
      },
      dayOfYear: {
        wide: "day of year",
        short: "day of yr.",
        narrow: "day of yr."
      },
      weekday: {
        wide: "day of the week",
        short: "day of wk.",
        narrow: "day of wk."
      },
      weekdayOfMonth: {
        wide: "weekday of the month",
        short: "wkday. of mo.",
        narrow: "wkday. of mo."
      },
      dayperiod: {
        short: "AM/PM",
        wide: "AM/PM",
        narrow: "AM/PM"
      },
      hour: {
        wide: "hour",
        short: "hr.",
        narrow: "hr."
      },
      minute: {
        wide: "minute",
        short: "min.",
        narrow: "min."
      },
      second: {
        wide: "second",
        short: "sec.",
        narrow: "sec."
      },
      zone: {
        wide: "time zone",
        short: "zone",
        narrow: "zone"
      },
      millisecond: {
        narrow: "ms",
        short: "ms",
        wide: "millisecond"
      }
    }
  },
  firstDay: 0
});