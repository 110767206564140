import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { keysOf, taggedNamespace } from '@mri-platform/shared/core';
import { SvgIconName } from '@mri/svg-icons/dist/ts/mri-icons';
import { RxState } from '@rx-angular/state';
import { selectSlice } from '@rx-angular/state/selections';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

type Position = 'left' | 'right';

const CONTAINER_POSITION = 'absolute' as const;
const CONTAINER_HEIGHT = '100%' as const;

const coverContainerStyles = {
  position: CONTAINER_POSITION,
  height: CONTAINER_HEIGHT
} as const;

interface ComponentState {
  clickOverlayToClose: boolean;
  headerTitle: string;
  hideHeader: boolean;
  isOpen: boolean;
  position: Position;
  overlayContainerOnly: boolean;
  headerIcon: SvgIconName | null;
}

type PublicState = Pick<ComponentState, 'headerTitle' | 'hideHeader' | 'isOpen' | 'position' | 'headerIcon'>;

type StyleOverrides = typeof coverContainerStyles | null;

interface Projections {
  containerOverlayStyles: StyleOverrides;
}

type ViewModel = PublicState & Projections;

const initialPublicState: PublicState = {
  headerTitle: '',
  hideHeader: false,
  isOpen: false,
  position: 'right',
  headerIcon: null
};

const initialState: ComponentState = {
  ...initialPublicState,
  clickOverlayToClose: false,
  overlayContainerOnly: false
};

@Component({
  selector: 'mri-shared-drawer',
  templateUrl: './drawer.component.html',
  styles: [
    `
      :host {
        display: block;
      }
      .align-center {
        align-items: center;
      }
      .word-break-all {
        word-break: break-all;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class DrawerComponent {
  @Input() set headerTitle(headerTitle: string) {
    this.state.set({ headerTitle });
  }

  /**
   * Whether the drawer should open from the 'right' (default) or 'left'
   */
  @Input() set position(position: Position) {
    this.state.set({ position });
  }

  @Input() set isOpen(isOpen: boolean) {
    this.state.set({ isOpen });
  }

  /**
   * Set to true to hide the built-in header for a blank-canvas drawer
   */
  @Input() set hideHeader(hideHeader: boolean) {
    this.state.set({ hideHeader });
  }

  /**
   * Set to true to enable "click off" style closing where clicking the overlay closes the drawer.
   */
  @Input() set clickOverlayToClose(clickOverlayToClose: boolean) {
    this.state.set({ clickOverlayToClose });
  }

  /**
   * Set to true to only overlay the element that the drawer is nested in, and not the entire screen.
   */
  @Input() set overlayContainerOnly(overlayContainerOnly: boolean) {
    this.state.set({ overlayContainerOnly });
  }

  /**
   * @type SvgIconName
   */
  @Input() set headerIcon(headerIcon: SvgIconName) {
    this.state.set({ headerIcon });
  }

  @Output() closeDrawer = new EventEmitter();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  drawerBodyTemplate!: TemplateRef<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  drawerFooterTemplate!: TemplateRef<any>;

  vm$: Observable<ViewModel>;

  constructor(private state: RxState<ComponentState>) {
    const tag = taggedNamespace('DrawerComponent');

    // Set initial state
    this.state.set(initialState);

    // Connect any observable-driven items to state for items in ComponentState...
    // none

    // Create projections (calculations from ComponentState)...

    const styles$: Observable<StyleOverrides> = this.state.select(
      selectSlice(['overlayContainerOnly', 'isOpen']),
      map(({ overlayContainerOnly, isOpen }) => (overlayContainerOnly && isOpen ? coverContainerStyles : null))
    );

    // Create ViewModel (Projections + PublicState)...

    const publicState$ = this.state.select(selectSlice(keysOf(initialPublicState)));
    this.vm$ = combineLatest([publicState$, styles$]).pipe(
      map(([publicState, containerOverlayStyles]) => ({ ...publicState, containerOverlayStyles })),
      tag('vm')
    );

    // side effects if any...
    // none
  }

  overlayClick() {
    if (this.state.get('clickOverlayToClose')) {
      this.closeDrawer.emit();
    }
  }
}
