import { EntityName } from '@mri-platform/import-export/core';
import { AuthzContext, CrudAction } from '@mri-platform/resource-authz';

export enum JobUsageCustomAction {
  DownloadReportAcrossClients = 'DownloadReportAcrossClients'
}

export type JobUsageAction = JobUsageCustomAction | CrudAction;
export const JobUsageAction = { ...JobUsageCustomAction, ...CrudAction };

export const JobUsage = {
  entityName: 'JobUsage',
  authorization: {
    [CrudAction.Read]: AuthzContext.readFor(EntityName.JobUsage),
    [JobUsageAction.DownloadReportAcrossClients]: {
      action: [JobUsageAction.DownloadReportAcrossClients, JobUsageAction.Read],
      resource: EntityName.JobUsage
    }
  }
};
