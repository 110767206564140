const MONTH = 'month';
const HOUR = 'hour';
const ZONE = 'zone';
const WEEKDAY = 'weekday';
const QUARTER = 'quarter';
const DATE_FIELD_MAP = {
  'G': 'era',
  'y': 'year',
  'q': QUARTER,
  'Q': QUARTER,
  'M': MONTH,
  'L': MONTH,
  'd': 'day',
  'E': WEEKDAY,
  'c': WEEKDAY,
  'e': WEEKDAY,
  'h': HOUR,
  'H': HOUR,
  'k': HOUR,
  'K': HOUR,
  'm': 'minute',
  's': 'second',
  'S': 'millisecond',
  'a': 'dayperiod',
  'x': ZONE,
  'X': ZONE,
  'z': ZONE,
  'Z': ZONE
};
const dateFormatRegExp = /d{1,2}|E{1,6}|e{1,6}|c{3,6}|c{1}|M{1,5}|L{1,5}|y{1,4}|H{1,2}|h{1,2}|k{1,2}|K{1,2}|m{1,2}|a{1,5}|s{1,2}|S{1,3}|z{1,4}|Z{1,5}|x{1,5}|X{1,5}|G{1,5}|q{1,5}|Q{1,5}|"[^"]*"|'[^']*'/g;
export { dateFormatRegExp, DATE_FIELD_MAP };