import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateRange, FilterConfig, FilterType } from '@mri-platform/import-export/common-state';
import { FormModel } from '@mri-platform/shared/common-ui';

@Component({
  selector: 'mri-ie-filter-bar-item',
  templateUrl: './filter-bar-item.component.html',
  styleUrls: ['./filter-bar-item.component.scss'],
  styles: [
    `
      :host {
        display: block;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterBarItemComponent implements OnInit {
  @Input() set config(value: FilterConfig) {
    if (!value) {
      return;
    }
    this.title = value.title;
    this.fieldName = this.title.toLocaleLowerCase();
    this.type = value.type;
    this.value = value.value || null;
    this.date.from = new Date(value.dateRange?.from || this.today);
    this.date.to = new Date(value.dateRange?.to || this.today);
    this.listValues = value.listValues || [];
    this.filterForm = this.createForm(value);

    if (this.type === FilterType.DateRange) {
      this.initialFormState['from'] = this.date.from;
      this.initialFormState['to'] = this.date.to;
    } else {
      this.initialFormState[this.fieldName] = this.value || null;
    }
    this.filterForm.patchValue(this.initialFormState);
  }

  @Input() set form(form: FormGroup) {
    this._form = form;
  }
  get form() {
    return this._form;
  }

  get values() {
    if (this.type === FilterType.List) {
      const formValue = this.form.value[this.fieldName] || null;
      return this.listValues.find(item => item.value === formValue)?.text;
    }
    return this.form.value;
  }

  FilterType: typeof FilterType = FilterType;
  filterForm: FormGroup = new FormGroup({});
  _form: FormGroup = new FormGroup({});
  initialFormState: { [key: string]: unknown } = {};
  fieldName = '';
  title = '';
  type = '';
  value: string | null = null;
  date: DateRange = {
    from: new Date(),
    to: new Date()
  };
  today = new Date();
  listValues: Array<{ text: string; value: string | null }> = [];
  showPopover = false;
  popUpRetention = false;
  popupSettings = {
    appendTo: 'component'
  };

  @ViewChild('anchor') public anchor?: ElementRef;
  @ViewChild('popup', { read: ElementRef }) public popup?: ElementRef;

  ngOnInit() {
    this.form.valueChanges.subscribe(x => {
      this.filterForm.patchValue(x);
    });
  }

  constructor(private fb: FormBuilder) {}

  public togglePopover(show?: boolean): void {
    this.showPopover = show !== undefined ? show : !this.showPopover;
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: Event): void {
    if (!this.contains(event.target) && !this.popUpRetention) {
      this.togglePopover(false);
    }
    this.popUpRetention = false;
  }

  private contains(target: EventTarget | null): boolean {
    return (
      this.anchor?.nativeElement.contains(target) || (this.popup ? this.popup.nativeElement.contains(target) : false)
    );
  }

  createForm(config: FilterConfig) {
    const group: FormModel<{ [key: string]: unknown }> = {};
    if (config.type === FilterType.DateRange) {
      group['from'] = [config.dateRange?.from];
      group['to'] = [config.dateRange?.to];
    } else {
      group[this.fieldName] = [config.value];
    }
    return this.fb.group(group);
  }

  setValues() {
    this.form.patchValue(this.filterForm.value);
    this.showPopover = false;
  }

  cancel() {
    this.filterForm.patchValue(this.form.value);
    this.showPopover = false;
  }

  reset() {
    this.filterForm.patchValue(this.initialFormState);
  }

  RetainPopUp() {
    this.popUpRetention = true;
  }
}
