/* eslint-disable @typescript-eslint/no-explicit-any */
import { Inject, Injectable, Optional } from '@angular/core';
import {
  ENTITY_METADATA_TOKEN,
  EntityDefinition,
  EntityDefinitionService,
  EntityMetadata,
  EntityMetadataMap,
  createEntityDefinition
} from '@ngrx/data';
import { BehaviorSubject, Observable } from 'rxjs';
import { DEFAULT_ENTITY_METADATA_TOKEN, EntityMetadataFactory } from './default-entity-metadata';
import { EntityMetadataExtentions, ExtendedEntityMetadata, isExtendedEntityMetadata } from './extended-entity-meta';
import { queryCacheAdapter } from './query-cache';

export interface ExtendedEntityDefinition<T extends object> extends EntityDefinition<T>, EntityMetadataExtentions<T> {}

@Injectable()
export class ExtendedEntityDefinitionService extends EntityDefinitionService {
  private getDefaults: EntityMetadataFactory<any>;
  // Registered entity name
  registeredEntityNames = new BehaviorSubject<string[]>([]);
  registeredEntityNames$: Observable<string[]> = this.registeredEntityNames.asObservable();

  constructor(
    @Inject(DEFAULT_ENTITY_METADATA_TOKEN)
    defaultEntityMetadata: EntityMetadataFactory<any> | Partial<ExtendedEntityMetadata<any>>,
    @Optional()
    @Inject(ENTITY_METADATA_TOKEN)
    entityMetadataMaps?: EntityMetadataMap[]
  ) {
    super([]);

    this.getDefaults =
      typeof defaultEntityMetadata === 'function' ? defaultEntityMetadata : () => defaultEntityMetadata;

    if (entityMetadataMaps) {
      entityMetadataMaps.forEach(x => this.registerMetadataMap(x));
    }
  }

  /**
   * Create and register the {ExtendedEntityDefinition} for the {EntityMetadata} of an entity type
   * @param definition - {ExtendedEntityMetadata} for a collection for that entity type
   *
   * Examples:
   *   registerMetadata(myHeroEntityDefinition);
   */
  registerMetadata<T extends object>(metadata: EntityMetadata | ExtendedEntityMetadata<T>) {
    if (!isExtendedEntityMetadata(metadata)) {
      throw new Error('ExtendedEntityMetadata expected to be registered');
    }

    const { authorization } = metadata;
    const defaults = this.getDefaults(metadata);
    const additionalCollectionState = {
      ...((defaults as any).additionalCollectionState ?? {}),
      ...((metadata as any).additionalCollectionState ?? {}),
      queryCache: queryCacheAdapter.getInitialState()
    };
    const definition: ExtendedEntityDefinition<T> = {
      ...createEntityDefinition({ ...defaults, ...metadata, additionalCollectionState }),
      authorization
    };
    this.registerDefinition(definition);
    // Update entity name for menu items to filter
    const entityNames = this.registeredEntityNames.value;
    const updatedEntityNames = [...entityNames, metadata.entityName];
    this.registeredEntityNames.next(updatedEntityNames);
  }

  /**
   * Get the {ExtendedEntityDefinition} of an entity type
   * @param entityName - the name of the type
   */
  getExtendedDefinition<T extends object>(entityName: string, shouldThrow?: boolean): ExtendedEntityDefinition<T> {
    return super.getDefinition<T>(entityName, shouldThrow) as ExtendedEntityDefinition<T>;
  }
}

export const ExtendedEntityDefinitionProvider = [
  { provide: EntityDefinitionService, useClass: ExtendedEntityDefinitionService },
  { provide: ExtendedEntityDefinitionService, useExisting: EntityDefinitionService }
];
