/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuditLogEntityList, AuditLogFilter, AuditLogOption } from '@mri-platform/import-export/common-state';
import { FormModel, FormState, connectForm } from '@mri-platform/shared/common-ui';
import { keysOf } from '@mri-platform/shared/core';
import { RxState } from '@rx-angular/state';
import { selectSlice } from '@rx-angular/state/selections';
import { Observable } from 'rxjs';

const today = new Date();
const yesterday = new Date();
yesterday.setDate(today.getDate() - 1);
const auditLogOptions: AuditLogOption[] = AuditLogEntityList.map((option, index) => {
  return { text: option, value: index + 1 };
});

const initialFormState: FormModel<AuditLogFilter> = {
  audits: [[], Validators.required],
  fromDate: [yesterday, Validators.required],
  toDate: [today, Validators.required]
};

interface ComponentState extends FormState<AuditLogFilter> {
  allAuditLogs: boolean;
  auditLogOptions: AuditLogOption[];
}

type PublicState = Pick<ComponentState, 'isValid' | 'isDirty' | 'auditLogOptions' | 'allAuditLogs'>;

const initialPublicState: PublicState = {
  isValid: false,
  isDirty: false,
  allAuditLogs: false,
  auditLogOptions: auditLogOptions
};

type ViewModel = PublicState;

const initialState: ComponentState = {
  ...initialPublicState,
  model: undefined as never
};

@Component({
  selector: 'mri-ie-audit-log-drawer',
  templateUrl: './audit-log-drawer.component.html',
  styleUrls: ['./audit-log-drawer.component.scss'],
  providers: [RxState],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuditLogsDrawerComponent {
  get model() {
    return this.state.get('model');
  }
  get selectedAuditLog() {
    return this.form.controls.audits.value as AuditLogOption[];
  }
  get allAuditLogs() {
    return this.state.get('allAuditLogs');
  }
  set allAuditLogs(allAuditLogs) {
    this.state.set({ allAuditLogs });
  }
  set selectedAuditLog(selectedAuditLog) {
    this.form.controls.audits.setValue(selectedAuditLog);
  }

  @Output() valueChanges = new EventEmitter<AuditLogFilter>();
  @Output() closeDrawer = new EventEmitter<void>();

  vm$: Observable<ViewModel>;

  form = this.createForm();

  constructor(
    private fb: FormBuilder,
    private state: RxState<ComponentState>
  ) {
    this.state.set(initialState);
    connectForm(this.form, this.state);
    this.vm$ = this.state.select(selectSlice(keysOf(initialPublicState)));
  }

  private createForm() {
    return this.fb.group(initialFormState);
  }

  save() {
    this.valueChanges.emit({ ...this.model, audits: this.selectedAuditLog.map(x => x.text) });
  }

  public get isIndet() {
    return this.selectedAuditLog.length !== 0 && this.selectedAuditLog.length !== auditLogOptions.length;
  }

  public isItemSelected(item: AuditLogOption) {
    return this.selectedAuditLog.some(x => x.value === item.value);
  }

  public selectAllAudits() {
    this.allAuditLogs = !this.allAuditLogs;
    this.selectedAuditLog = this.allAuditLogs ? auditLogOptions.slice() : [];
  }

  public onValueChange() {
    this.allAuditLogs = this.selectedAuditLog.length === auditLogOptions.length;
  }
}
