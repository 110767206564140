import compact from 'lodash-es/compact';
import concat from 'lodash-es/concat';
import difference from 'lodash-es/difference';
import extend from 'lodash-es/extend';
import flatMap from 'lodash-es/flatMap';
import groupBy from 'lodash-es/groupBy';
import includes from 'lodash-es/includes';
import intersection from 'lodash-es/intersection';
import isString from 'lodash-es/isString';
import keys from 'lodash-es/keys';
import map from 'lodash-es/map';
import union from 'lodash-es/union';
import uniq from 'lodash-es/uniq';
import uniqBy from 'lodash-es/uniqBy';
import { of, throwError } from 'rxjs';
import {
  AuthzContext,
  AuthzContextLong,
  AuthzContextsActionMap,
  AuthzLongResource,
  AuthzResource
} from './authz-context';
import { AuthzResult, AuthzResultsMap, deniedAuthzResult } from './authz-result';
import { ClaimsAuthzCtxParserService } from './claims-authz-ctx-parser';
import { defaultIfEmpty } from './lodash-extensions/default-if-empty';
import { findPropertyWhere } from './lodash-extensions/find-property-where';
import { isEqualIgnoringCase } from './lodash-extensions/is-equal-ignoring-case';
import { singleOrDefault } from './lodash-extensions/single-or-default';
import { toTitleCase } from './lodash-extensions/to-title-case';
import { UserPermission } from './user-permission';
import { WhiteListDataService } from './white-list-data';

//TODO: work out how to genericize this such that we don't need all the `any`s so we can re-enable this rule.
/* eslint-disable @typescript-eslint/no-explicit-any */
export class ClaimsAuthzService {
  constructor(
    private claimsAuthzCtxParserService: ClaimsAuthzCtxParserService,
    private whiteListDataService: WhiteListDataService
  ) {}

  /**
   * Extended from Parser Service
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=395&lineStyle=plain&lineEnd=396&lineStartColumn=1&lineEndColumn=1
   * @param authzContext
   */
  getSuppliedAuthzCtx(authzContext: AuthzContext | AuthzContext[]) {
    return this.claimsAuthzCtxParserService.getSuppliedAuthzCtx(authzContext);
  }

  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=141&lineStyle=plain&lineEnd=142&lineStartColumn=1&lineEndColumn=1
   * @param authzContext
   * @param userPermissions
   */
  checkAccess(authzContext: AuthzContext | AuthzContext[], userPermissions?: UserPermission[]): AuthzResult {
    authzContext = this.claimsAuthzCtxParserService.parseAuthzContext(authzContext, userPermissions);

    return this.checkParsedAuthzCtx(authzContext as AuthzContextLong);
  }

  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=150&lineStyle=plain&lineEnd=151&lineStartColumn=1&lineEndColumn=1
   * @param authzContext
   * @param userPermissions
   */
  checkAccessAsync(
    /*context | action, resource [,user]*/ authzContext: AuthzContext[],
    userPermissions: UserPermission[]
  ) {
    const access = this.checkAccess.apply(this, [authzContext, userPermissions]);
    if (access.isGranted) {
      return of(access.isGranted);
    }
    return throwError(access.reasons);
  }

  /**
   * https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=158&lineStyle=plain&lineEnd=159&lineStartColumn=1&lineEndColumn=1
   * @param parsedAuthzCtx
   */
  checkParsedAuthzCtx(parsedAuthzCtx: AuthzContextLong): AuthzResult {
    this.assertUserReady(parsedAuthzCtx.user);

    const resourceAccess = this.calcResourceAccess(parsedAuthzCtx);
    return this.createAccessResultSummary(resourceAccess);
  }

  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=171&lineStyle=plain&lineEnd=172&lineStartColumn=1&lineEndColumn=1
   * @param contexts
   * @param userPermissions
   */
  checkAccessMany(contexts: AuthzContext[] | AuthzContext, userPermissions?: UserPermission[]): AuthzResult[] {
    contexts = concat([], contexts);
    const parsedContexts = contexts.map((ctx: any) =>
      this.claimsAuthzCtxParserService.parseAuthzContext(ctx, userPermissions)
    );
    return this.checkParsedAuthzCtxMany(parsedContexts);
  }

  checkAccessMap<T extends AuthzContextsActionMap>(
    contextMap: T,
    userPermissions: UserPermission[]
  ): AuthzResultsMap<T> {
    const initialMap = {} as AuthzResultsMap<T>;
    return Object.entries(contextMap).reduce(
      (acc, [key, ctx]) => Object.assign(acc, { [key]: this.checkAccess(ctx, userPermissions) }),
      initialMap
    );
  }

  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=179&lineStyle=plain&lineEnd=180&lineStartColumn=1&lineEndColumn=1
   * @param parsedContexts
   */
  checkParsedAuthzCtxMany(parsedContexts: AuthzContextLong[]): AuthzResult[] {
    return parsedContexts.map(this.checkParsedAuthzCtx.bind(this));
  }

  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=192&lineStyle=plain&lineEnd=193&lineStartColumn=1&lineEndColumn=1
   * @param resourceAccess
   */
  createAccessResultSummary(resourceAccess: any) {
    const denyReasons = resourceAccess.filter((access: any) => access.denies.length);
    return {
      isGranted: !denyReasons.length,
      reasons: denyReasons
    };
  }

  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=165&lineStyle=plain&lineEnd=166&lineStartColumn=1&lineEndColumn=1
   * @param user
   */
  assertUserReady(user: any) {
    if (!user || user.isNotReady) {
      throw new Error('user must be initialised before claimsAuthzService can be used');
    }
  }

  /**
   * Check Same reason or not
   * Porting from: https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=234&lineStyle=plain&lineEnd=235&lineStartColumn=1&lineEndColumn=1
   * @param deny
   */
  private makeDenyComparable(deny: any) {
    // warning: this will not work for when resourceInstance is an actual object; problem is resourceInstance might not be serializable
    const { resourceInstance: _resourceInstance, ...serializable } = deny;
    return JSON.stringify(serializable);
  }

  /**
   * Calculation the resource acess
   * Porting From: https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=40&lineStyle=plain&lineEnd=41&lineStartColumn=1&lineEndColumn=1
   * @param authorisationContext
   */
  calcResourceAccess(authorisationContext: AuthzContextLong) {
    //const perms = uniqBy(authorisationContext.user?.rolePermissions, 'resourceName') || [];
    /* Note: The uniqBy was never part of the original code ported over from Ram.Series5 and therefore should not be required.
     The rolePermissions would contain multiple entries based on whether the resource has property or without property
     and action associated with each property, hence the uniqBy would cause problem in this case.
    */
    const perms = authorisationContext.user?.rolePermissions || [];

    if (Array.isArray(authorisationContext.resource)) {
      // we've been supplied an array of resource types
      return (authorisationContext.resource as AuthzResource[]).map((resourceDef: AuthzResource) => {
        const denies = this.getDeniesForResourceTypeDef(
          { type: resourceDef.type, prop: resourceDef.prop },
          authorisationContext.action,
          perms
        );
        return {
          resourceType: resourceDef.type,
          resourceInstance: resourceDef.type,
          denies: denies
        };
      });
    } else {
      // we've been supplied a hash map whose keys are resource type names and values are an array of
      // { instance: [], prop: [] } objects
      return flatMap(authorisationContext.resource, (resourceDefs: any, resourceName: any) => {
        const resourceTypeDeniesLookup = map(resourceDefs, def => ({
          key: def,
          denies: this.getDeniesForResourceTypeDef(
            { type: resourceName, prop: def.prop },
            authorisationContext.action,
            perms
          )
        }));

        const resourceDefsFlat = flatMap(resourceDefs, def =>
          map(def.instance, resourceInstance => ({ instance: resourceInstance, prop: def.prop, parentDef: def }))
        );

        return map(resourceDefsFlat, (def: any) => {
          // todo: when data roles supported refine typeLevelDenies for the individual instance
          const typeLevelDenies = findPropertyWhere(
            resourceTypeDeniesLookup as any,
            { key: def.parentDef },
            'denies',
            []
          );
          return {
            resourceType: toTitleCase(resourceName),
            resourceInstance: def.instance,
            denies: typeLevelDenies
          };
        });
      });
    }
  }

  /**
   * Denies For Resource
   * Porting From: https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=203&lineStyle=plain&lineEnd=204&lineStartColumn=1&lineEndColumn=1
   * @param resourceDef
   * @param requestedActions
   * @param permissions
   */
  getDeniesForResourceTypeDef(
    resourceDef: AuthzResource,
    requestedActions: string | string[],
    permissions: UserPermission[]
  ) {
    const permissionGapTable = this.calcResourceComponentPermissionGapTable(resourceDef, requestedActions, permissions);
    const gapsGroupedByDeniedActions = groupBy(permissionGapTable, gap => JSON.stringify(gap.deniedActions));
    return map(gapsGroupedByDeniedActions, resourceComponents => ({
      properties: uniq(compact(map(resourceComponents, 'property'))),
      actions: resourceComponents[0].deniedActions
    }));
  }

  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=122&lineStyle=plain&lineEnd=123&lineStartColumn=1&lineEndColumn=1
   * @param resourceDef
   * @param requestedActions
   * @param permissions
   */
  getResourcePropertiesGrantedPermission(
    resourceDef: AuthzLongResource,
    requestedActions: string | string[],
    permissions: UserPermission[]
  ) {
    return permissions.filter(
      (p: UserPermission) =>
        isEqualIgnoringCase(p.resourceName, resourceDef.type) &&
        // currently resourceProperties not supported so I commented out
        // this.containsMatchingItems(p.resourceProperties, resourceDef.prop[0]) &&
        this.getGrantedActions(requestedActions, p.grantedOperations).length
    );
  }

  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=130&lineStyle=plain&lineEnd=131&lineStartColumn=1&lineEndColumn=1
   * @param sourceA
   * @param sourceB
   */
  containsMatchingItems(sourceA: string | string[], sourceB: string | string[]) {
    if (!sourceA || !sourceA.length || !sourceB || !sourceB.length) {
      return false;
    }

    sourceA = isString(sourceA) ? sourceA.split(',') : sourceA;
    sourceB = isString(sourceB) ? sourceB.split(',') : sourceB;
    return this.getGrantedActions(sourceB, sourceA).length;
  }

  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=92&lineStyle=plain&lineEnd=93&lineStartColumn=1&lineEndColumn=1
   * @param resourceDef
   * @param requestedActions
   * @param permissions
   */
  calcResourceComponentPermissionGapTable(
    resourceDef: AuthzResource,
    requestedActions: string | string[],
    permissions: UserPermission[]
  ) {
    const resourceComponents = map(defaultIfEmpty(resourceDef.prop), (propertyName: any) => ({
      type: resourceDef.type,
      property: propertyName
    }));

    const entityResourcePerms = this.getEntityGrantedPermission(resourceDef, permissions);
    const propertiesPerms = this.getResourcePropertiesGrantedPermission(resourceDef, requestedActions, permissions);
    const grantedPermissions = union([entityResourcePerms], propertiesPerms);
    return resourceComponents
      .map((component: any) => {
        const grantedActions = this.getGrantedActionsForResourceComponent(
          component,
          requestedActions,
          grantedPermissions as any
        );
        return extend({}, component, {
          deniedActions: difference(requestedActions, grantedActions)
        });
      })
      .filter((component: any) => component.deniedActions.length);
  }
  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=115&lineStyle=plain&lineEnd=116&lineStartColumn=1&lineEndColumn=1
   * @param resourceDef
   * @param permissions
   */
  getEntityGrantedPermission(resourceDef: AuthzLongResource, permissions: UserPermission[]) {
    return singleOrDefault(
      permissions,
      (p: UserPermission) =>
        isEqualIgnoringCase(p.resourceName, resourceDef.type) &&
        (!p.resourceProperties || (p.resourceProperties && !p.resourceProperties.length))
    );
  }

  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=219&lineStyle=plain&lineEnd=220&lineStartColumn=1&lineEndColumn=1
   * @param resourceComponent
   * @param requestedActions
   * @param permissions
   */
  getGrantedActionsForResourceComponent(
    resourceComponent: AuthzLongResource,
    requestedActions: string | string[],
    permissions: UserPermission[]
  ) {
    const grantedOperations: string[] = [];
    permissions.forEach((permission: any) => {
      const hasPermissionForResource = this.isResourceComponentCoveredByPermission(permission, resourceComponent);
      if (hasPermissionForResource) {
        grantedOperations.push(...this.getGrantedActions(requestedActions, permission.grantedOperations));
      }
    });
    return grantedOperations;
  }

  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=239&lineStyle=plain&lineEnd=240&lineStartColumn=1&lineEndColumn=1
   * @param permission
   * @param resourceComponent
   */
  isResourceComponentCoveredByPermission(permission: UserPermission, resourceComponent: AuthzLongResource) {
    if (!permission || !isEqualIgnoringCase(permission.resourceName, resourceComponent.type)) {
      return false;
    }

    if (!permission.resourceProperties || !permission.resourceProperties.length) {
      // permissions granted to the entire resource (which covers any requested properties)
      return true;
    }

    if (permission.resourceProperties.length && !resourceComponent.property) {
      // permissions have been granted for individual properties, but the requested permission is
      // for the entire resource
      return false;
    }

    // permission requested for individual property and granted permissions are property level
    return isString(resourceComponent.property)
      ? permission.resourceProperties.includes(resourceComponent.property)
      : false;
  }

  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=259&lineStyle=plain&lineEnd=260&lineStartColumn=1&lineEndColumn=1
   * @param results
   * @param passCriteria
   */
  mergeAccessCheckResults(results: AuthzResult[], passCriteria?: (results: AuthzResult[]) => boolean): AuthzResult {
    if (results.length === 0) {
      return deniedAuthzResult;
    }
    const isGranted = (passCriteria || this.passWhenAllPass)(results);
    return results.slice(1).reduce(
      (aggr, next) => ({
        isGranted: isGranted,
        reasons: uniqBy(aggr.reasons.concat(next.reasons), this.makeDenyComparable)
      }),
      results[0]
    );
  }

  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=271&lineStyle=plain&lineEnd=272&lineStartColumn=1&lineEndColumn=1
   * @param list
   */
  mergeAllAuthzCtxs(list: any) {
    list = compact(list);
    const allCtxKeys = uniq(concat(list, map(keys(list))));
    return allCtxKeys.reduce((result: any, ctxKey: any) => {
      result[ctxKey] = flatMap(list, ctxKey);
      return result;
    }, {});
  }

  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=282&lineStyle=plain&lineEnd=283&lineStartColumn=1&lineEndColumn=1
   * @param results
   */
  passWhenAllPass(results: AuthzResult[]) {
    return results.every(r => r.isGranted);
  }

  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=288&lineStyle=plain&lineEnd=289&lineStartColumn=1&lineEndColumn=1
   * @param results
   */
  passWhenSomePass(results: any) {
    return results.some((r: any) => r.isGranted);
  }

  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=294&lineStyle=plain&lineEnd=295&lineStartColumn=1&lineEndColumn=1
   * @param entityName
   */
  isWhitelistedEntity(entityName: string) {
    // need to improve in future
    return includes(this.whiteListDataService.getWhitelistData().entities, entityName);
  }

  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=721&lineStyle=plain&lineEnd=722&lineStartColumn=1&lineEndColumn=1
   * @param name
   */
  escapeResourcePropertyNameSeparator(name: string) {
    return this.claimsAuthzCtxParserService.escapeResourcePropertyNameSeparator(name);
  }

  private getGrantedActions(requestedActions: string | string[], grantedActions: string[]) {
    requestedActions = Array.isArray(requestedActions) ? requestedActions : [requestedActions];
    const crudOps = intersection(requestedActions, grantedActions);
    const crudAllOps = requestedActions.filter(x => !x.endsWith('All') && grantedActions.includes(x.concat('All')));

    return union(crudOps, crudAllOps);
  }
}
