import { cldr } from './info';
import { CURRENCY, ACCOUNTING, DECIMAL, CURRENCY_PLACEHOLDER, NUMBER_PLACEHOLDER, LIST_SEPARATOR, GROUP_SEPARATOR, POINT } from '../common/constants';
const LATIN_NUMBER_FORMATS = "Formats-numberSystem-latn";
const LATIN_NUMBER_SYMBOLS = "symbols-numberSystem-latn";
const patternRegExp = /([#,0.]+)/g;
const cldrCurrencyRegExp = /¤/g;
function getPatterns(pattern) {
  patternRegExp.lastIndex = 0;
  return pattern.replace(cldrCurrencyRegExp, CURRENCY_PLACEHOLDER).replace(patternRegExp, NUMBER_PLACEHOLDER).split(LIST_SEPARATOR);
}
function getGroupSize(pattern) {
  patternRegExp.lastIndex = 0;
  const numberPatterns = patternRegExp.exec(pattern.split(LIST_SEPARATOR)[0])[0].split(POINT);
  const integer = numberPatterns[0];
  const groupSize = integer.split(GROUP_SEPARATOR).slice(1).map(function (group) {
    return group.length;
  }).reverse();
  return groupSize;
}
function loadCurrencyUnitPatterns(currencyInfo, currencyFormats) {
  for (let field in currencyFormats) {
    if (field.startsWith("unitPattern")) {
      currencyInfo[field] = currencyFormats[field].replace("{0}", NUMBER_PLACEHOLDER).replace("{1}", CURRENCY_PLACEHOLDER);
    }
  }
}
export default function loadNumbersInfo(locale, info) {
  const localeInfo = cldr[locale];
  const numbers = localeInfo.numbers = localeInfo.numbers || {};
  numbers.symbols = numbers.symbols || {};
  for (let field in info) {
    if (field === LATIN_NUMBER_SYMBOLS) {
      Object.assign(numbers.symbols, info[field]);
    } else if (field.includes(LATIN_NUMBER_FORMATS)) {
      const style = field.substr(0, field.indexOf(LATIN_NUMBER_FORMATS));
      const pattern = info[field].standard;
      numbers[style] = {
        patterns: getPatterns(pattern)
      };
      if (style === CURRENCY) {
        numbers[style].groupSize = getGroupSize((info[DECIMAL + LATIN_NUMBER_FORMATS] || info[field]).standard);
        loadCurrencyUnitPatterns(numbers[style], info[field]);
        numbers[ACCOUNTING] = {
          patterns: getPatterns(info[field][ACCOUNTING]),
          groupSize: numbers[style].groupSize
        };
      } else {
        numbers[style].groupSize = getGroupSize(pattern);
      }
    } else if (field === "currencies") {
      numbers.currencies = info[field];
    }
  }
}