/**-----------------------------------------------------------------------------------------
* Copyright © 2024 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
var setData = require('@progress/kendo-angular-intl').setData;
setData({
  name: "pl",
  likelySubtags: {
    pl: "pl-Latn-PL"
  },
  identity: {
    language: "pl"
  },
  territory: "PL",
  calendar: {
    patterns: {
      d: "d.MM.y",
      D: "EEEE, d MMMM y",
      m: "d MMM",
      M: "d MMMM",
      y: "LLL y",
      Y: "LLLL y",
      F: "EEEE, d MMMM y HH:mm:ss",
      g: "d.MM.y HH:mm",
      G: "d.MM.y HH:mm:ss",
      t: "HH:mm",
      T: "HH:mm:ss",
      s: "yyyy'-'MM'-'dd'T'HH':'mm':'ss",
      u: "yyyy'-'MM'-'dd HH':'mm':'ss'Z'"
    },
    dateTimeFormats: {
      full: "{1} {0}",
      long: "{1} {0}",
      medium: "{1}, {0}",
      short: "{1}, {0}",
      availableFormats: {
        Bh: "h B",
        Bhm: "h:mm B",
        Bhms: "h:mm:ss B",
        d: "d",
        E: "ccc",
        EBhm: "E h:mm B",
        EBhms: "E h:mm:ss B",
        Ed: "E, d",
        Ehm: "E, h:mm a",
        EHm: "E, HH:mm",
        Ehms: "E, h:mm:ss a",
        EHms: "E, HH:mm:ss",
        Gy: "y G",
        GyMd: "d.MM.y GGGGG",
        GyMMM: "MMM y G",
        GyMMMd: "d MMM y G",
        GyMMMEd: "E, d MMM y G",
        GyMMMM: "LLLL y G",
        GyMMMMd: "d MMMM y G",
        GyMMMMEd: "E, d MMMM y G",
        h: "h a",
        H: "HH",
        hm: "h:mm a",
        Hm: "HH:mm",
        hms: "h:mm:ss a",
        Hms: "HH:mm:ss",
        hmsv: "h:mm:ss a v",
        Hmsv: "HH:mm:ss v",
        hmv: "h:mm a v",
        Hmv: "HH:mm v",
        M: "L",
        Md: "d.MM",
        MEd: "E, d.MM",
        MMM: "LLL",
        MMMd: "d MMM",
        MMMEd: "E, d MMM",
        MMMMd: "d MMMM",
        MMMMEd: "E, d MMMM",
        "MMMMW-count-one": "LLLL, 'tydz'. W",
        "MMMMW-count-few": "LLLL, 'tydz'. W",
        "MMMMW-count-many": "LLLL, 'tydz'. W",
        "MMMMW-count-other": "LLLL, 'tydz'. W",
        ms: "mm:ss",
        y: "y",
        yM: "MM.y",
        yMd: "d.MM.y",
        yMEd: "E, d.MM.y",
        yMMM: "LLL y",
        yMMMd: "d MMM y",
        yMMMEd: "E, d MMM y",
        yMMMM: "LLLL y",
        yMMMMd: "d MMMM y",
        yMMMMEd: "E, d MMMM y",
        yQQQ: "QQQ y",
        yQQQQ: "QQQQ y",
        "yw-count-one": "Y, 'tydz'. w",
        "yw-count-few": "Y, 'tydz'. w",
        "yw-count-many": "Y, 'tydz'. w",
        "yw-count-other": "Y, 'tydz'. w"
      }
    },
    timeFormats: {
      full: "HH:mm:ss zzzz",
      long: "HH:mm:ss z",
      medium: "HH:mm:ss",
      short: "HH:mm"
    },
    dateFormats: {
      full: "EEEE, d MMMM y",
      long: "d MMMM y",
      medium: "d MMM y",
      short: "d.MM.y"
    },
    days: {
      format: {
        abbreviated: ["niedz.", "pon.", "wt.", "śr.", "czw.", "pt.", "sob."],
        narrow: ["n", "p", "w", "ś", "c", "p", "s"],
        short: ["nie", "pon", "wto", "śro", "czw", "pią", "sob"],
        wide: ["niedziela", "poniedziałek", "wtorek", "środa", "czwartek", "piątek", "sobota"]
      },
      "stand-alone": {
        abbreviated: ["niedz.", "pon.", "wt.", "śr.", "czw.", "pt.", "sob."],
        narrow: ["N", "P", "W", "Ś", "C", "P", "S"],
        short: ["nie", "pon", "wto", "śro", "czw", "pią", "sob"],
        wide: ["niedziela", "poniedziałek", "wtorek", "środa", "czwartek", "piątek", "sobota"]
      }
    },
    months: {
      format: {
        abbreviated: ["sty", "lut", "mar", "kwi", "maj", "cze", "lip", "sie", "wrz", "paź", "lis", "gru"],
        narrow: ["s", "l", "m", "k", "m", "c", "l", "s", "w", "p", "l", "g"],
        wide: ["stycznia", "lutego", "marca", "kwietnia", "maja", "czerwca", "lipca", "sierpnia", "września", "października", "listopada", "grudnia"]
      },
      "stand-alone": {
        abbreviated: ["sty", "lut", "mar", "kwi", "maj", "cze", "lip", "sie", "wrz", "paź", "lis", "gru"],
        narrow: ["S", "L", "M", "K", "M", "C", "L", "S", "W", "P", "L", "G"],
        wide: ["styczeń", "luty", "marzec", "kwiecień", "maj", "czerwiec", "lipiec", "sierpień", "wrzesień", "październik", "listopad", "grudzień"]
      }
    },
    quarters: {
      format: {
        abbreviated: ["I kw.", "II kw.", "III kw.", "IV kw."],
        narrow: ["1", "2", "3", "4"],
        wide: ["I kwartał", "II kwartał", "III kwartał", "IV kwartał"]
      },
      "stand-alone": {
        abbreviated: ["I kw.", "II kw.", "III kw.", "IV kw."],
        narrow: ["1", "2", "3", "4"],
        wide: ["I kwartał", "II kwartał", "III kwartał", "IV kwartał"]
      }
    },
    dayPeriods: {
      format: {
        abbreviated: {
          midnight: "o północy",
          am: "AM",
          noon: "w południe",
          pm: "PM",
          morning1: "rano",
          morning2: "przed południem",
          afternoon1: "po południu",
          evening1: "wieczorem",
          night1: "w nocy"
        },
        narrow: {
          midnight: "o półn.",
          am: "a",
          noon: "w poł.",
          pm: "p",
          morning1: "rano",
          morning2: "przed poł.",
          afternoon1: "po poł.",
          evening1: "wiecz.",
          night1: "w nocy"
        },
        wide: {
          midnight: "o północy",
          am: "AM",
          noon: "w południe",
          pm: "PM",
          morning1: "rano",
          morning2: "przed południem",
          afternoon1: "po południu",
          evening1: "wieczorem",
          night1: "w nocy"
        }
      },
      "stand-alone": {
        abbreviated: {
          midnight: "północ",
          am: "AM",
          noon: "południe",
          pm: "PM",
          morning1: "rano",
          morning2: "przedpołudnie",
          afternoon1: "popołudnie",
          evening1: "wieczór",
          night1: "noc"
        },
        narrow: {
          midnight: "półn.",
          am: "a",
          noon: "poł.",
          pm: "p",
          morning1: "rano",
          morning2: "przedpoł.",
          afternoon1: "popoł.",
          evening1: "wiecz.",
          night1: "noc"
        },
        wide: {
          midnight: "północ",
          am: "AM",
          noon: "południe",
          pm: "PM",
          morning1: "rano",
          morning2: "przedpołudnie",
          afternoon1: "popołudnie",
          evening1: "wieczór",
          night1: "noc"
        }
      }
    },
    eras: {
      format: {
        wide: {
          "0": "przed naszą erą",
          "1": "naszej ery",
          "0-alt-variant": "p.n.e.",
          "1-alt-variant": "n.e."
        },
        abbreviated: {
          "0": "p.n.e.",
          "1": "n.e.",
          "0-alt-variant": "BCE",
          "1-alt-variant": "CE"
        },
        narrow: {
          "0": "p.n.e.",
          "1": "n.e.",
          "0-alt-variant": "BCE",
          "1-alt-variant": "CE"
        }
      }
    },
    gmtFormat: "GMT{0}",
    gmtZeroFormat: "GMT",
    dateFields: {
      era: {
        wide: "era",
        short: "era",
        narrow: "era"
      },
      year: {
        wide: "rok",
        short: "r.",
        narrow: "r."
      },
      quarter: {
        wide: "kwartał",
        short: "kw.",
        narrow: "kw."
      },
      month: {
        wide: "miesiąc",
        short: "mies.",
        narrow: "mc"
      },
      week: {
        wide: "tydzień",
        short: "tydz.",
        narrow: "tydz."
      },
      weekOfMonth: {
        wide: "tydzień miesiąca",
        short: "tydz. mies.",
        narrow: "tydz. mies."
      },
      day: {
        wide: "dzień",
        short: "dz.",
        narrow: "d."
      },
      dayOfYear: {
        wide: "dzień roku",
        short: "dz. roku",
        narrow: "dz. r."
      },
      weekday: {
        wide: "dzień tygodnia",
        short: "dzień tyg.",
        narrow: "dz. tyg."
      },
      weekdayOfMonth: {
        wide: "dzień miesiąca",
        short: "dzień mies.",
        narrow: "dz. mies."
      },
      dayperiod: {
        short: "rano / po południu / wieczorem",
        wide: "rano / po południu / wieczorem",
        narrow: "rano / po poł. / wiecz."
      },
      hour: {
        wide: "godzina",
        short: "godz.",
        narrow: "g."
      },
      minute: {
        wide: "minuta",
        short: "min",
        narrow: "min"
      },
      second: {
        wide: "sekunda",
        short: "sek.",
        narrow: "s"
      },
      zone: {
        wide: "strefa czasowa",
        short: "str. czasowa",
        narrow: "str. czas."
      },
      millisecond: {
        narrow: "ms",
        short: "ms",
        wide: "milisekunda"
      }
    }
  },
  firstDay: 1
});