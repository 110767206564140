/**-----------------------------------------------------------------------------------------
* Copyright © 2024 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
var setData = require('@progress/kendo-angular-intl').setData;
setData({
  name: "ko",
  likelySubtags: {
    ko: "ko-Kore-KR"
  },
  identity: {
    language: "ko"
  },
  territory: "KR",
  calendar: {
    patterns: {
      d: "y. M. d.",
      D: "y년 M월 d일 EEEE",
      m: "MMM d일",
      M: "MMMM d일",
      y: "y년 MMM",
      Y: "y년 MMMM",
      F: "y년 M월 d일 EEEE a h:mm:ss",
      g: "y. M. d. a h:mm",
      G: "y. M. d. a h:mm:ss",
      t: "a h:mm",
      T: "a h:mm:ss",
      s: "yyyy'-'MM'-'dd'T'HH':'mm':'ss",
      u: "yyyy'-'MM'-'dd HH':'mm':'ss'Z'"
    },
    dateTimeFormats: {
      full: "{1} {0}",
      long: "{1} {0}",
      medium: "{1} {0}",
      short: "{1} {0}",
      availableFormats: {
        Bh: "B h시",
        Bhm: "B h:mm",
        Bhms: "B h:mm:ss",
        d: "d일",
        E: "ccc",
        EBhm: "(E) B h:mm",
        EBhms: "(E) B h:mm:ss",
        Ed: "d일 (E)",
        EEEEd: "d일 EEEE",
        Ehm: "(E) a h:mm",
        EHm: "(E) HH:mm",
        Ehms: "(E) a h:mm:ss",
        EHms: "(E) HH:mm:ss",
        Gy: "G y년",
        GyMd: "GGGGG y/M/d",
        GyMMM: "G y년 MMM",
        GyMMMd: "G y년 MMM d일",
        GyMMMEd: "G y년 MMM d일 (E)",
        GyMMMEEEEd: "G y년 MMM d일 EEEE",
        h: "a h시",
        H: "H시",
        HHmmss: "HH:mm:ss",
        hm: "a h:mm",
        Hm: "HH:mm",
        hms: "a h:mm:ss",
        Hms: "H시 m분 s초",
        hmsv: "a h:mm:ss v",
        Hmsv: "H시 m분 s초 v",
        hmv: "a h:mm v",
        Hmv: "HH:mm v",
        M: "M월",
        Md: "M. d.",
        MEd: "M. d. (E)",
        MEEEEd: "M. d. EEEE",
        MMM: "LLL",
        MMMd: "MMM d일",
        MMMEd: "MMM d일 (E)",
        MMMEEEEd: "MMM d일 EEEE",
        MMMMd: "MMMM d일",
        "MMMMW-count-other": "MMMM W번째 주",
        mmss: "mm:ss",
        ms: "mm:ss",
        y: "y년",
        yM: "y. M.",
        yMd: "y. M. d.",
        yMEd: "y. M. d. (E)",
        yMEEEEd: "y. M. d. EEEE",
        yMM: "y. M.",
        yMMM: "y년 MMM",
        yMMMd: "y년 MMM d일",
        yMMMEd: "y년 MMM d일 (E)",
        yMMMEEEEd: "y년 MMM d일 EEEE",
        yMMMM: "y년 MMMM",
        yQQQ: "y년 QQQ",
        yQQQQ: "y년 QQQQ",
        "yw-count-other": "Y년 w번째 주"
      }
    },
    timeFormats: {
      full: "a h시 m분 s초 zzzz",
      long: "a h시 m분 s초 z",
      medium: "a h:mm:ss",
      short: "a h:mm"
    },
    dateFormats: {
      full: "y년 M월 d일 EEEE",
      long: "y년 M월 d일",
      medium: "y. M. d.",
      short: "yy. M. d."
    },
    days: {
      format: {
        abbreviated: ["일", "월", "화", "수", "목", "금", "토"],
        narrow: ["일", "월", "화", "수", "목", "금", "토"],
        short: ["일", "월", "화", "수", "목", "금", "토"],
        wide: ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"]
      },
      "stand-alone": {
        abbreviated: ["일", "월", "화", "수", "목", "금", "토"],
        narrow: ["일", "월", "화", "수", "목", "금", "토"],
        short: ["일", "월", "화", "수", "목", "금", "토"],
        wide: ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"]
      }
    },
    months: {
      format: {
        abbreviated: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
        narrow: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
        wide: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"]
      },
      "stand-alone": {
        abbreviated: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
        narrow: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
        wide: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"]
      }
    },
    quarters: {
      format: {
        abbreviated: ["1분기", "2분기", "3분기", "4분기"],
        narrow: ["1", "2", "3", "4"],
        wide: ["제 1/4분기", "제 2/4분기", "제 3/4분기", "제 4/4분기"]
      },
      "stand-alone": {
        abbreviated: ["1분기", "2분기", "3분기", "4분기"],
        narrow: ["1", "2", "3", "4"],
        wide: ["제 1/4분기", "제 2/4분기", "제 3/4분기", "제 4/4분기"]
      }
    },
    dayPeriods: {
      format: {
        abbreviated: {
          midnight: "자정",
          am: "AM",
          noon: "정오",
          pm: "PM",
          morning1: "새벽",
          morning2: "오전",
          afternoon1: "오후",
          evening1: "저녁",
          night1: "밤"
        },
        narrow: {
          midnight: "자정",
          am: "AM",
          noon: "정오",
          pm: "PM",
          morning1: "새벽",
          morning2: "오전",
          afternoon1: "오후",
          evening1: "저녁",
          night1: "밤"
        },
        wide: {
          midnight: "자정",
          am: "오전",
          noon: "정오",
          pm: "오후",
          morning1: "새벽",
          morning2: "오전",
          afternoon1: "오후",
          evening1: "저녁",
          night1: "밤"
        }
      },
      "stand-alone": {
        abbreviated: {
          midnight: "자정",
          am: "AM",
          noon: "정오",
          pm: "PM",
          morning1: "새벽",
          morning2: "오전",
          afternoon1: "오후",
          evening1: "저녁",
          night1: "밤"
        },
        narrow: {
          midnight: "자정",
          am: "AM",
          noon: "정오",
          pm: "PM",
          morning1: "새벽",
          morning2: "오전",
          afternoon1: "오후",
          evening1: "저녁",
          night1: "밤"
        },
        wide: {
          midnight: "자정",
          am: "오전",
          noon: "정오",
          pm: "오후",
          morning1: "새벽",
          morning2: "오전",
          afternoon1: "오후",
          evening1: "저녁",
          night1: "밤"
        }
      }
    },
    eras: {
      format: {
        wide: {
          "0": "기원전",
          "1": "서기",
          "0-alt-variant": "BCE",
          "1-alt-variant": "CE"
        },
        abbreviated: {
          "0": "BC",
          "1": "AD",
          "0-alt-variant": "BCE",
          "1-alt-variant": "CE"
        },
        narrow: {
          "0": "BC",
          "1": "AD",
          "0-alt-variant": "BCE",
          "1-alt-variant": "CE"
        }
      }
    },
    gmtFormat: "GMT{0}",
    gmtZeroFormat: "GMT",
    dateFields: {
      era: {
        wide: "연호",
        short: "연호",
        narrow: "연호"
      },
      year: {
        wide: "년",
        short: "년",
        narrow: "년"
      },
      quarter: {
        wide: "분기",
        short: "분기",
        narrow: "분기"
      },
      month: {
        wide: "월",
        short: "월",
        narrow: "월"
      },
      week: {
        wide: "주",
        short: "주",
        narrow: "주"
      },
      weekOfMonth: {
        wide: "월의 주",
        short: "월의 주",
        narrow: "월의 주"
      },
      day: {
        wide: "일",
        short: "일",
        narrow: "일"
      },
      dayOfYear: {
        wide: "년의 일",
        short: "년의 일",
        narrow: "년의 일"
      },
      weekday: {
        wide: "요일",
        short: "요일",
        narrow: "요일"
      },
      weekdayOfMonth: {
        wide: "월의 평일",
        short: "월의 평일",
        narrow: "월의 평일"
      },
      dayperiod: {
        short: "오전/오후",
        wide: "오전/오후",
        narrow: "오전/오후"
      },
      hour: {
        wide: "시",
        short: "시",
        narrow: "시"
      },
      minute: {
        wide: "분",
        short: "분",
        narrow: "분"
      },
      second: {
        wide: "초",
        short: "초",
        narrow: "초"
      },
      zone: {
        wide: "시간대",
        short: "시간대",
        narrow: "시간대"
      },
      millisecond: {
        narrow: "ms",
        short: "ms",
        wide: "밀리초"
      }
    }
  },
  firstDay: 0
});