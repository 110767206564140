import { cldr } from './info';
const predefinedDatePatterns = {
  s: "yyyy'-'MM'-'dd'T'HH':'mm':'ss",
  u: "yyyy'-'MM'-'dd HH':'mm':'ss'Z'"
};
const YEAR_REGEX = /y+/g;
const SHORT_DATE = [["dateFormats", "short"]];
const datePatterns = {
  D: [["dateFormats", "full"]],
  m: [["dateTimeFormats", "availableFormats", "MMMd"]],
  M: [["dateTimeFormats", "availableFormats", "MMMMd"]],
  y: [["dateTimeFormats", "availableFormats", "yMMM"]],
  Y: [["dateTimeFormats", "availableFormats", "yMMMM"]],
  F: [["dateFormats", "full"], ["timeFormats", "medium"]],
  g: [["dateTimeFormats", "availableFormats", "yMd"], ["timeFormats", "short"]],
  G: [["dateTimeFormats", "availableFormats", "yMd"], ["timeFormats", "medium"]],
  t: [["timeFormats", "short"]],
  T: [["timeFormats", "medium"]]
};
function toArray(obj) {
  let result = [];
  let names = Object.getOwnPropertyNames(obj);
  for (let idx = 0; idx < names.length; idx++) {
    let value = obj[names[idx]];
    result.push(value);
  }
  return result;
}
function getCalendarNames(info, isObj) {
  const result = {};
  for (let formatType in info) {
    let names = result[formatType] = {};
    for (let format in info[formatType]) {
      let formats = info[formatType][format];
      names[format] = isObj ? formats : toArray(formats);
    }
  }
  return result;
}
function getEraNames(eras) {
  const result = {};
  const format = result.format = {};
  const eraNameMap = {
    eraAbbr: "abbreviated",
    eraNames: "wide",
    eraNarrow: "narrow"
  };
  for (let eraFormatName in eras) {
    let formatName = eraNameMap[eraFormatName];
    format[formatName] = eras[eraFormatName];
  }
  return result;
}
function loadCalendarNames(locale, calendar) {
  const localeCalendar = cldr[locale].calendar;
  localeCalendar.days = getCalendarNames(calendar.days);
  localeCalendar.months = getCalendarNames(calendar.months);
  localeCalendar.quarters = getCalendarNames(calendar.quarters);
  localeCalendar.dayPeriods = getCalendarNames(calendar.dayPeriods, true);
  localeCalendar.eras = getEraNames(calendar.eras);
}
function loadCalendarDateFields(locale, fields) {
  const localeCalendar = cldr[locale].calendar;
  const dateFields = {};
  for (let field in fields) {
    const [fieldName, formatType = 'wide'] = field.split('-');
    const fieldInfo = dateFields[fieldName] || {};
    const displayName = fields[field].displayName;
    if (!displayName) {
      continue;
    }
    fieldInfo[formatType] = displayName;
    dateFields[fieldName] = fieldInfo;
  }
  localeCalendar.dateFields = dateFields;
}
function getPredefinedFormat(paths, calendar) {
  const result = [];
  for (let pathIdx = 0; pathIdx < paths.length; pathIdx++) {
    let fields = paths[pathIdx];
    let pattern = calendar;
    for (let idx = 0; idx < fields.length; idx++) {
      pattern = pattern[fields[idx]];
    }
    result.push(pattern);
  }
  return result.join(" ");
}
function loadCalendarPatterns(locale, calendar) {
  const cldrCalendar = cldr[locale].calendar;
  const patterns = cldrCalendar.patterns = {};
  patterns.d = getPredefinedFormat(SHORT_DATE, calendar).replace(YEAR_REGEX, 'y');
  for (let pattern in datePatterns) {
    patterns[pattern] = getPredefinedFormat(datePatterns[pattern], calendar);
  }
  for (let pattern in predefinedDatePatterns) {
    patterns[pattern] = predefinedDatePatterns[pattern];
  }
  const dateTimeFormats = calendar.dateTimeFormats;
  cldrCalendar.dateTimeFormats = {
    full: dateTimeFormats.full,
    long: dateTimeFormats.long,
    medium: dateTimeFormats.medium,
    short: dateTimeFormats.short,
    availableFormats: dateTimeFormats.availableFormats
  };
  cldrCalendar.timeFormats = calendar.timeFormats;
  cldrCalendar.dateFormats = calendar.dateFormats;
}
export default function loadCalendarInfo(locale, info) {
  const calendar = cldr[locale].calendar = cldr[locale].calendar || {};
  for (let field in info) {
    if (field === "timeZoneNames") {
      calendar.gmtFormat = info[field].gmtFormat;
      calendar.gmtZeroFormat = info[field].gmtZeroFormat;
    } else if (field === "calendars" && info[field].gregorian) {
      loadCalendarPatterns(locale, info[field].gregorian);
      loadCalendarNames(locale, info[field].gregorian);
    } else if (field === "fields") {
      loadCalendarDateFields(locale, info.fields);
    }
  }
}