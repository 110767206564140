export interface AuthzResult {
  isGranted: boolean;
  reasons: unknown[]; // todo: implement DenyReason
}

export const grantedAuthzResult: AuthzResult = {
  isGranted: true,
  reasons: []
};

export const deniedAuthzResult: AuthzResult = {
  isGranted: false,
  reasons: []
};

export interface AuthzMessage {
  message: string;
}

export type AuthzResultsMap<T> = {
  [Property in keyof T]: AuthzResult;
};
