import isMatchWith from 'lodash-es/isMatchWith';

const caseInsensitiveComparison = (objValue: unknown, srcValue: unknown) => {
  if (typeof objValue === 'string' && typeof srcValue === 'string') {
    return objValue.toLocaleUpperCase() === srcValue.toLocaleUpperCase();
  } else {
    return undefined; // let someone else do the comparison
  }
};

/**
 * Returns a search function that will perform a partial deep case-insensitive comparison
 * between each item in a list supplied and the `criteria` object to determine if that
 * item contains equivalent property values.
 *
 * For more information about how the comparison is performed see
 * https://lodash.com/docs/#isMatch
 *
 * @param criteria the property values to match each item against
 */
export function findByMatch<T>(criteria: Partial<T>) {
  return (list: T[]) => list.filter(item => isMatchWith(item as object, criteria, caseInsensitiveComparison));
}
