import { HttpClient } from '@angular/common/http';
import { Injectable, Optional, Provider } from '@angular/core';
import { ChangeSet, DefaultDataServiceConfig, EntityCacheDataService, EntityDefinitionService } from '@ngrx/data';
import { Observable } from 'rxjs';

type EntityCacheDataServiceSelector = (url: string, changeSet: ChangeSet<unknown>) => void;

@Injectable()
export abstract class EntityCacheDataServices {
  abstract registerService(serviceSelector: EntityCacheDataServiceSelector, service: EntityCacheDataService): void;
}

interface SelectableEntityCacheDataService {
  selector: EntityCacheDataServiceSelector;
  service: EntityCacheDataService;
}

@Injectable()
export class DelegatingEntityCacheDataService
  implements Pick<EntityCacheDataService, 'saveEntities'>, EntityCacheDataServices
{
  private defaultEntityCacheDataService: EntityCacheDataService;
  private services: SelectableEntityCacheDataService[] = [];

  constructor(
    entityDefinitionService: EntityDefinitionService,
    http: HttpClient,
    @Optional() config?: DefaultDataServiceConfig
  ) {
    this.defaultEntityCacheDataService = new EntityCacheDataService(entityDefinitionService, http, config);
  }

  /**
   * Register an `EntityCacheDataService` that will called to persist a `ChangeSet`
   *
   * The order that the services are registered is important. The first service whose `serviceSelector`
   * function returns true will be selected to receive the `saveEntities` call.
   *
   * Where there are no explicitly registered services whose `serviceSelector` returns true,
   * the default ngrx-data `EntityCacheDataService` will be used to service the `saveEntities` call
   *
   * @example
   * entityCacheDataServices
   *   .registerService(url => url.includes('/foos'), myEntityCacheDataService);
   *
   * @example
   * entityCacheDataServices
   *   .registerService((_, changeSet) => changeSet.tag === 'Blah', myEntityCacheDataService);
   *
   * @param serviceSelector function that will receive the url and the ChangeSet to be persisted and should
   * return true for the `service` being registered to be selected to perform the save action
   * @param service the service that knows how to save the ChangeSet that it will be asked to persist
   */
  registerService(serviceSelector: EntityCacheDataServiceSelector, service: EntityCacheDataService) {
    if (service === (this as unknown)) {
      throw new Error(
        `Trying to register the same 'DelegatingEntityCacheDataService' with itself; this would create an infinit loop`
      );
    }
    this.services = [...this.services, { selector: serviceSelector, service }];
  }

  saveEntities(changeSet: ChangeSet<unknown>, url: string): Observable<ChangeSet<unknown>> {
    const candidate = this.services.find(({ selector }) => selector(url, changeSet));
    const service = candidate?.service || this.defaultEntityCacheDataService;
    return service.saveEntities(changeSet, url);
  }
}

export const DelegatingEntityCacheDataServiceProvider: Provider[] = [
  DelegatingEntityCacheDataService,
  { provide: EntityCacheDataService, useExisting: DelegatingEntityCacheDataService },
  { provide: EntityCacheDataServices, useExisting: DelegatingEntityCacheDataService }
];
