import { parseInlineStyles } from '@progress/kendo-common';
export const setStyle = (element, styleString) => {
  const styles = parseInlineStyles(styleString);
  Object.keys(styles).forEach(key => {
    element.style[key] = styles[key];
  });
};
const styleAttr = 'data-style';
export const replaceStyleAttr = html => (html || '').replace(/\sstyle=/g, ' ' + styleAttr + '=');
export const restoreStyleAttr = container => {
  Array.from(container.querySelectorAll('[' + styleAttr + ']')).forEach(element => {
    const styleString = element.getAttribute(styleAttr);
    element.removeAttribute(styleAttr);
    setStyle(element, styleString);
  });
};
export const setInnerHTML = (container, html) => {
  container.innerHTML = replaceStyleAttr(html);
  restoreStyleAttr(container);
};