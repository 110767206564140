import TemplateService from './services/template-service';
import getter from './utils/getter';
import map from './utils/map';
function defaultGroupHeaderTemplate(data) {
  return `${data.title}: ${data.value}`;
}
function createArray(length, callback) {
  const result = [];
  for (let idx = 0; idx < length; idx++) {
    result.push(callback(idx));
  }
  return result;
}
function defaultItemId(item) {
  return item.id;
}
class ExcelExporter {
  constructor(options) {
    options.columns = this._trimColumns(options.columns || []);
    this.allColumns = map(this._leafColumns(options.columns || []), this._prepareColumn);
    this.columns = this._visibleColumns(this.allColumns);
    this.options = options;
    this.data = options.data || [];
    this.aggregates = options.aggregates || {};
    this.groups = [].concat(options.groups || []);
    this.hasGroups = this.groups.length > 0;
    this.hierarchy = options.hierarchy;
    this.hasGroupHeaderColumn = this.columns.some(column => column.groupHeaderColumnTemplate);
    this.collapsible = this.options.collapsible;
  }
  workbook() {
    const workbook = {
      sheets: [{
        columns: this._columns(),
        rows: this.hierarchy ? this._hierarchyRows() : this._rows(),
        freezePane: this._freezePane(),
        filter: this._filter()
      }]
    };
    return workbook;
  }
  _trimColumns(columns) {
    return columns.filter(column => {
      let result = Boolean(column.field);
      if (!result && column.columns) {
        result = this._trimColumns(column.columns).length > 0;
      }
      return result;
    });
  }
  _leafColumns(columns) {
    let result = [];
    for (let idx = 0; idx < columns.length; idx++) {
      if (!columns[idx].columns) {
        result.push(columns[idx]);
      } else {
        result = result.concat(this._leafColumns(columns[idx].columns));
      }
    }
    return result;
  }
  _prepareColumn(column) {
    if (!column.field) {
      return null;
    }
    let value = function (dataItem) {
      return getter(column.field, true)(dataItem);
    };
    let values = null;
    if (column.values) {
      values = {};
      column.values.forEach(function (item) {
        values[item.value] = item.text;
      });
      value = function (dataItem) {
        return values[getter(column.field, true)(dataItem)];
      };
    }
    return Object.assign({}, column, {
      value: value,
      values: values,
      groupHeaderTemplate: column.groupHeaderTemplate ? TemplateService.compile(column.groupHeaderTemplate) : defaultGroupHeaderTemplate,
      groupHeaderColumnTemplate: column.groupHeaderColumnTemplate ? TemplateService.compile(column.groupHeaderColumnTemplate) : null,
      groupFooterTemplate: column.groupFooterTemplate ? TemplateService.compile(column.groupFooterTemplate) : null,
      footerTemplate: column.footerTemplate ? TemplateService.compile(column.footerTemplate) : null
    });
  }
  _filter() {
    if (!this.options.filterable) {
      return null;
    }
    const depth = this._depth();
    return {
      from: depth,
      to: depth + this.columns.length - 1
    };
  }
  _createPaddingCells(length) {
    return createArray(length, () => Object.assign({
      background: "#dfdfdf",
      color: "#333"
    }, this.options.paddingCellOptions));
  }
  _dataRow(dataItem, level, depth) {
    let cells = this._createPaddingCells(level);

    // grouped
    if (this.hasGroups && depth && dataItem.items) {
      cells = cells.concat(this._groupHeaderCells(dataItem, level, depth));
      const rows = this._dataRows(dataItem.items, level + 1);
      rows.unshift({
        type: "group-header",
        cells: cells,
        level: this.collapsible ? level : null
      });
      return rows.concat(this._footer(dataItem, level));
    }
    const dataCells = [];
    for (let cellIdx = 0; cellIdx < this.columns.length; cellIdx++) {
      dataCells[cellIdx] = this._cell(dataItem, this.columns[cellIdx]);
    }
    if (this.hierarchy) {
      dataCells[0].colSpan = depth - level + 1;
    }
    return [{
      type: "data",
      cells: cells.concat(dataCells),
      level: this.collapsible ? level : null
    }];
  }
  _groupHeaderCells(dataItem, level, depth) {
    const cells = [];
    const column = this.allColumns.filter(function (column) {
      return column.field === dataItem.field;
    })[0] || {};
    const title = column && column.title ? column.title : dataItem.field;
    const template = column ? column.groupHeaderTemplate || column.groupHeaderColumnTemplate : null;
    const group = Object.assign({
      title: title,
      field: dataItem.field,
      value: column && column.values ? column.values[dataItem.value] : dataItem.value,
      aggregates: dataItem.aggregates,
      items: dataItem.items
    }, dataItem.aggregates[dataItem.field]);
    const value = template ? template(group) : `${title}: ${dataItem.value}`;
    cells.push(Object.assign({
      value: value,
      background: "#dfdfdf",
      color: "#333",
      colSpan: (this.hasGroupHeaderColumn ? 1 : this.columns.length) + depth - level
    }, column.groupHeaderCellOptions));
    if (this.hasGroupHeaderColumn) {
      this.columns.forEach(function (column, index) {
        if (index > 0) {
          cells.push(Object.assign({
            background: "#dfdfdf",
            color: "#333",
            value: column.groupHeaderColumnTemplate ? column.groupHeaderColumnTemplate(Object.assign({
              group: group
            }, group, dataItem.aggregates[column.field])) : undefined
          }, column.groupHeaderCellOptions));
        }
      });
    }
    return cells;
  }
  _dataRows(dataItems, level) {
    const depth = this._depth();
    const rows = [];
    for (let idx = 0; idx < dataItems.length; idx++) {
      rows.push.apply(rows, this._dataRow(dataItems[idx], level, depth));
    }
    return rows;
  }
  _hierarchyRows() {
    const depth = this._depth();
    const data = this.data;
    const itemLevel = this.hierarchy.itemLevel;
    const itemId = this.hierarchy.itemId || defaultItemId;
    const hasFooter = this._hasFooterTemplate();
    const rows = [];
    const parents = [];
    let previousLevel = 0;
    let previousItemId;
    if (!hasFooter) {
      this.collapsible = false;
    }
    for (let idx = 0; idx < data.length; idx++) {
      const item = data[idx];
      const level = itemLevel(item, idx);
      if (hasFooter) {
        if (level > previousLevel) {
          parents.push({
            id: previousItemId,
            level: previousLevel
          });
        } else if (level < previousLevel) {
          rows.push.apply(rows, this._hierarchyFooterRows(parents, level, depth));
        }
        previousLevel = level;
        previousItemId = itemId(item, idx);
      }
      rows.push.apply(rows, this._dataRow(item, level + 1, depth));
    }
    if (hasFooter) {
      rows.push.apply(rows, this._hierarchyFooterRows(parents, 0, depth));
      const rootAggregate = data.length ? this.aggregates[data[0].parentId] : {};
      rows.push(this._hierarchyFooter(rootAggregate, 0, depth));
    }
    this._prependHeaderRows(rows);
    return rows;
  }
  _hierarchyFooterRows(parents, currentLevel, depth) {
    const rows = [];
    while (parents.length && parents[parents.length - 1].level >= currentLevel) {
      const parent = parents.pop();
      rows.push(this._hierarchyFooter(this.aggregates[parent.id], parent.level + 1, depth));
    }
    return rows;
  }
  _hasFooterTemplate() {
    const columns = this.columns;
    for (let idx = 0; idx < columns.length; idx++) {
      if (columns[idx].footerTemplate) {
        return true;
      }
    }
  }
  _hierarchyFooter(aggregates, level, depth) {
    const cells = this.columns.map(function (column, index) {
      const colSpan = index ? 1 : depth - level + 1;
      if (column.footerTemplate) {
        const fieldAggregates = (aggregates || {})[column.field];
        return Object.assign({
          background: "#dfdfdf",
          color: "#333",
          colSpan: colSpan,
          value: column.footerTemplate(Object.assign({
            aggregates: aggregates
          }, fieldAggregates))
        }, column.footerCellOptions);
      }
      return Object.assign({
        background: "#dfdfdf",
        color: "#333",
        colSpan: colSpan
      }, column.footerCellOptions);
    });
    return {
      type: "footer",
      cells: this._createPaddingCells(level).concat(cells),
      level: this.collapsible ? level : null
    };
  }
  _footer(dataItem, level) {
    const rows = [];
    const footer = this.columns.some(column => column.groupFooterTemplate);
    let templateData, group;
    if (footer) {
      group = {
        group: {
          items: dataItem.items,
          field: dataItem.field,
          value: dataItem.value
        }
      };
      templateData = {};
      Object.keys(dataItem.aggregates).forEach(key => {
        templateData[key] = Object.assign({}, dataItem.aggregates[key], group);
      });
    }
    const cells = this.columns.map(column => {
      if (column.groupFooterTemplate) {
        let data = Object.assign({}, templateData, dataItem.aggregates[column.field], group);
        return Object.assign({
          background: "#dfdfdf",
          color: "#333",
          value: column.groupFooterTemplate(data)
        }, column.groupFooterCellOptions);
      }
      return Object.assign({
        background: "#dfdfdf",
        color: "#333"
      }, column.groupFooterCellOptions);
    });
    if (footer) {
      rows.push({
        type: "group-footer",
        cells: this._createPaddingCells(this.groups.length).concat(cells),
        level: this.collapsible ? level : null
      });
    }
    return rows;
  }
  _isColumnVisible(column) {
    return this._visibleColumns([column]).length > 0 && (column.field || column.columns);
  }
  _visibleColumns(columns) {
    return columns.filter(column => {
      let exportable = column.exportable;
      if (typeof exportable === 'object') {
        exportable = column.exportable.excel;
      }
      const visibleInExport = !column.hidden && exportable !== false;
      const visibleInExportOnly = column.hidden && exportable === true;
      let visible = visibleInExport || visibleInExportOnly;
      if (visible && column.columns) {
        visible = this._visibleColumns(column.columns).length > 0;
      }
      return visible;
    });
  }
  _headerRow(row, groups) {
    const headers = row.cells.map(function (cell) {
      return Object.assign(cell, {
        colSpan: cell.colSpan > 1 ? cell.colSpan : 1,
        rowSpan: row.rowSpan > 1 && !cell.colSpan ? row.rowSpan : 1
      });
    });
    if (this.hierarchy && headers[0].firstCell) {
      headers[0].colSpan += this._depth();
    }
    return {
      type: "header",
      cells: createArray(groups.length, () => Object.assign({
        background: "#7a7a7a",
        color: "#fff"
      }, this.options.headerPaddingCellOptions)).concat(headers)
    };
  }
  _prependHeaderRows(rows) {
    const groups = this.groups;
    const headerRows = [{
      rowSpan: 1,
      cells: [],
      index: 0
    }];
    this._prepareHeaderRows(headerRows, this.options.columns);
    for (let idx = headerRows.length - 1; idx >= 0; idx--) {
      rows.unshift(this._headerRow(headerRows[idx], groups));
    }
  }
  _prepareHeaderRows(rows, columns, parentCell, parentRow) {
    const row = parentRow || rows[rows.length - 1];
    let childRow = rows[row.index + 1];
    let totalColSpan = 0;
    for (let idx = 0; idx < columns.length; idx++) {
      const column = columns[idx];
      if (this._isColumnVisible(column)) {
        const cell = Object.assign({
          background: "#7a7a7a",
          color: "#fff",
          value: column.title || column.field,
          colSpan: 0,
          firstCell: idx === 0 && (!parentCell || parentCell.firstCell)
        }, column.headerCellOptions);
        row.cells.push(cell);
        if (column.columns && column.columns.length) {
          if (!childRow) {
            childRow = {
              rowSpan: 0,
              cells: [],
              index: rows.length
            };
            rows.push(childRow);
          }
          cell.colSpan = this._trimColumns(this._visibleColumns(column.columns)).length;
          this._prepareHeaderRows(rows, column.columns, cell, childRow);
          totalColSpan += cell.colSpan - 1;
          row.rowSpan = rows.length - row.index;
        }
      }
    }
    if (parentCell) {
      parentCell.colSpan += totalColSpan;
    }
  }
  _rows() {
    const rows = this._dataRows(this.data, 0);
    if (this.columns.length) {
      this._prependHeaderRows(rows);
      let footer = false;
      const cells = this.columns.map(column => {
        if (column.footerTemplate) {
          footer = true;
          return Object.assign({
            background: "#dfdfdf",
            color: "#333",
            value: column.footerTemplate(Object.assign({}, this.aggregates, this.aggregates[column.field]))
          }, column.footerCellOptions);
        }
        return Object.assign({
          background: "#dfdfdf",
          color: "#333"
        }, column.footerCellOptions);
      });
      if (footer) {
        rows.push({
          type: "footer",
          cells: this._createPaddingCells(this.groups.length).concat(cells)
        });
      }
    }
    return rows;
  }
  _headerDepth(columns) {
    const result = 1;
    let max = 0;
    for (let idx = 0; idx < columns.length; idx++) {
      if (columns[idx].columns) {
        const temp = this._headerDepth(columns[idx].columns);
        if (temp > max) {
          max = temp;
        }
      }
    }
    return result + max;
  }
  _freezePane() {
    const columns = this._visibleColumns(this.options.columns || []);
    const colSplit = this._visibleColumns(this._trimColumns(this._leafColumns(columns.filter(function (column) {
      return column.locked;
    })))).length;
    return {
      rowSplit: this._headerDepth(columns),
      colSplit: colSplit ? colSplit + this.groups.length : 0
    };
  }
  _cell(dataItem, column) {
    return Object.assign({
      value: column.value(dataItem)
    }, column.cellOptions);
  }
  _depth() {
    let depth = 0;
    if (this.hierarchy) {
      depth = this.hierarchy.depth;
    } else {
      depth = this.groups.length;
    }
    return depth;
  }
  _columns() {
    const depth = this._depth();
    const columns = createArray(depth, () => ({
      width: 20
    }));
    return columns.concat(this.columns.map(function (column) {
      return {
        width: parseInt(column.width, 10),
        autoWidth: column.width ? false : true
      };
    }));
  }
}
export default ExcelExporter;