import { b as bootstrapLazy } from './index-562f2dbd.js';
export { s as setNonce } from './index-562f2dbd.js';
import { g as globalScripts } from './app-globals-0f993ce5.js';
const defineCustomElements = async (win, options) => {
  if (typeof window === 'undefined') return undefined;
  await globalScripts();
  return bootstrapLazy([["mri-drawer", [[1, "mri-drawer", {
    "heading": [1],
    "loading": [4],
    "size": [1],
    "icon": [1],
    "opened": [1540],
    "showBackButton": [1540, "show-back-button"],
    "showFooter": [32],
    "close": [64],
    "open": [64]
  }, null, {
    "opened": ["openedChanged"],
    "showBackButton": ["showBackButtonChanged"]
  }]]], ["mri-accordion-section", [[1, "mri-accordion-section", {
    "closed": [1540],
    "counter": [2],
    "header": [1],
    "status": [513]
  }]]], ["mri-hider", [[1, "mri-hider", {
    "labelPosition": [1, "label-position"],
    "hiderPosition": [1, "hider-position"],
    "closed": [1540],
    "closedLabel": [1, "closed-label"],
    "openedLabel": [1, "opened-label"]
  }]]], ["mri-navigation-button-menu", [[1, "mri-navigation-button-menu", {
    "heading": [1],
    "icon": [1],
    "flixVideoAvailable": [4, "flix-video-available"],
    "menuWidth": [32],
    "visibleAreaMaxWidth": [32],
    "visible": [32],
    "hideMenuElement": [64],
    "showMenuElement": [64],
    "showMenuElementOnly": [64]
  }, [[9, "resize", "windowResize"]]]]], ["mri-accordion", [[1, "mri-accordion"]]], ["mri-hotkey", [[1, "mri-hotkey", {
    "alt": [4],
    "control": [4],
    "keyName": [1, "key-name"],
    "shift": [4]
  }]]], ["mri-navigation-menu-group", [[1, "mri-navigation-menu-group", {
    "heading": [1],
    "getMenuItems": [64]
  }]]], ["mri-navigation-menu-item", [[1, "mri-navigation-menu-item", {
    "label": [1],
    "link": [1],
    "getLinkElement": [64]
  }]]], ["mri-split-button", [[1, "mri-split-button"]]], ["mri-success-stamp", [[1, "mri-success-stamp", {
    "confetti": [4],
    "autoRun": [4, "auto-run"],
    "yay": [64]
  }]]], ["mri-switch", [[1, "mri-switch", {
    "colorScheme": [1, "color-scheme"],
    "value": [516],
    "disabled": [4],
    "onLabel": [1, "on-label"],
    "offLabel": [1, "off-label"]
  }]]], ["mri-counter", [[1, "mri-counter", {
    "status": [513],
    "value": [2]
  }]]], ["mri-overlay", [[1, "mri-overlay", {
    "dismissable": [4],
    "opened": [1540]
  }, [[4, "keydown", "documentKeydown"]]]]], ["mri-button_7", [[1, "mri-button", {
    "buttonWidth": [1, "button-width"],
    "colorScheme": [1, "color-scheme"],
    "disabled": [4],
    "icon": [1],
    "iconLocation": [1, "icon-location"],
    "label": [1],
    "size": [1],
    "state": [1],
    "variant": [1]
  }], [1, "mri-navigation-button", {
    "link": [1],
    "icon": [1],
    "flixVideoAvailable": [4, "flix-video-available"]
  }], [1, "mri-navigation-bar", {
    "menuOverlayHidden": [32],
    "hideMenuOverlay": [64],
    "showMenuOverlay": [64]
  }, [[8, "keyup", "documentKeyup"]]], [1, "mri-navigation-bar-wrapper"], [1, "mri-navigation-button-icon", {
    "icon": [1],
    "flixVideoAvailable": [4, "flix-video-available"]
  }], [1, "mri-spinner", {
    "colorScheme": [1, "color-scheme"],
    "size": [1]
  }], [1, "mri-icon", {
    "icon": [1],
    "size": [513]
  }]]], ["mri-flyout_2", [[1, "mri-icon-button", {
    "colorScheme": [1, "color-scheme"],
    "disabled": [4],
    "icon": [1],
    "label": [1],
    "size": [1],
    "state": [1]
  }], [1, "mri-flyout", {
    "dismissable": [4],
    "size": [1],
    "dontTrapFocus": [4, "dont-trap-focus"],
    "fillContainer": [516, "fill-container"],
    "opened": [1540],
    "side": [513],
    "close": [64],
    "open": [64]
  }, [[6, "focus", "documentFocus"]], {
    "opened": ["openedChanged"]
  }]]]], options);
};
export { defineCustomElements };