const FIELD_REGEX = /\[(?:(\d+)|['"](.*?)['"])\]|((?:(?!\[.*?\]|\.).)+)/g;
/**
 * @hidden
 */
export function fieldList(field) {
  const fields = [];
  field.replace(FIELD_REGEX, function (_match, index, indexAccessor, fieldName) {
    fields.push(index !== undefined ? index : indexAccessor || fieldName);
  });
  return fields;
}