import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'mri-shared-page-header',
  template: `
    <h1 class="mri-heading mri-heading--1 mri-page-header__title">
      <small class="mri-heading__eyebrow" [title]="eyeBrowTitle">
        {{ eyeBrowText }}
        <mri-icon *ngIf="eyeBrowIcon" class="mri-pt-2x-small" [icon]="eyeBrowIcon"></mri-icon>
      </small>
      <span class="mri-heading__text">
        {{ pageName }}
      </span>
    </h1>

    <div class="mri-toolbar mri-page-header__toolbar mri-page-header__page-toolbar">
      <ng-content select="[primaryButtons]"></ng-content>
    </div>
  `,
  styleUrl: 'page-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageHeaderComponent {
  @HostBinding('class.mri-page-header') hostClass = true;
  @Input() pageName = '';
  @Input() eyeBrowText = '';
  @Input() eyeBrowIcon = '';
  @Input() eyeBrowTitle = '';
}
