import { Injectable } from '@angular/core';
import { CanDeactivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { exhaustMap, first, map, tap } from 'rxjs/operators';
import { DirtyStatePromptService } from './dirty-state-prompt.service';

export interface EditableComponent {
  cancelChanges?: () => void;
  hasChanges(): Observable<boolean> | boolean;
  changesPrompt$?: () => Observable<boolean>;
}

export interface ForceNavigationState {
  forceNavigation?: boolean;
}

export const FORCE_ROUTER_NAVIGATION: ForceNavigationState = {
  forceNavigation: true
};

@Injectable({
  providedIn: 'root'
})
export class ComponentEditingGuard implements CanDeactivate<EditableComponent> {
  constructor(
    private router: Router,
    private dirtyPromptService: DirtyStatePromptService
  ) {}

  canDeactivate(component: EditableComponent): Observable<boolean> {
    if (this.isNavigationForced()) {
      return of(true);
    }
    const dirty = component.hasChanges();
    const dirty$ = dirty instanceof Observable ? dirty : of(dirty);

    return dirty$.pipe(
      exhaustMap(isDirty => {
        const proceed$ = isDirty
          ? component.changesPrompt$
            ? component.changesPrompt$()
            : this.dirtyPromptService.showNavigationPrompt$()
          : of(true);
        return proceed$.pipe(map(proceed => ({ proceed, isDirty })));
      }),
      tap(({ isDirty, proceed }) => {
        if (proceed && isDirty && component.cancelChanges) {
          component.cancelChanges();
        }
      }),
      map(({ proceed }) => proceed),
      first()
    );
  }

  private isNavigationForced() {
    const nav = this.router.getCurrentNavigation();
    if (!nav) {
      return false;
    }

    const state: ForceNavigationState = nav.extras.state || {};
    return !!state.forceNavigation;
  }
}
