// date packing utilities from Kendo Spreadsheet

// Julian days algorithms from http://www.hermetic.ch/cal_stud/jdn.htm#comp
function dateToJulianDays(y, m, d) {
  return (1461 * (y + 4800 + ((m - 13) / 12 | 0)) / 4 | 0) + (367 * (m - 1 - 12 * ((m - 13) / 12 | 0)) / 12 | 0) - (3 * ((y + 4900 + ((m - 13) / 12 | 0)) / 100 | 0) / 4 | 0) + d - 32075;
}

// This uses the Google Spreadsheet approach: treat 1899-12-31 as day 1, allowing to avoid
// implementing the "Leap Year Bug" yet still be Excel compatible for dates starting 1900-03-01.
const BASE_DATE = dateToJulianDays(1900, 0, -1);
function packDate(year, month, date) {
  return dateToJulianDays(year, month, date) - BASE_DATE;
}
function packTime(hh, mm, ss, ms) {
  return (hh + (mm + (ss + ms / 1000) / 60) / 60) / 24;
}
export default function dateToSerial(date) {
  const time = packTime(date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
  const serial = packDate(date.getFullYear(), date.getMonth(), date.getDate());
  return serial < 0 ? serial - 1 + time : serial + time;
}